import React, { Fragment } from 'react';

const PlHelpCenterRecentTopics = () => (
  <Fragment>
    <div className="sp-main__section sp-offset--t--md">
      <div className="sp-text">
        <div className="sp-placeholder" />
      </div>
    </div>
    <div className="sp-main__section sp-offset--t--cm">
      <div className="sp-text">
        <div className="sp-placeholder sp-placeholder--50" />
      </div>
    </div>
    <div className="sp-main__section sp-offset--t--cm">
      <div className="sp-text">
        <div className="sp-placeholder" />
      </div>
    </div>
    <div className="sp-main__section sp-offset--t--cm">
      <div className="sp-text">
        <div className="sp-placeholder sp-placeholder--50" />
      </div>
    </div>
  </Fragment>
);

export default PlHelpCenterRecentTopics;
