const queryToParams = (url) => url.replace('?', '').split('&').reduce((acc, el) => {
  const keyVal = el.split('=');

  return ({
    ...acc,
    [keyVal[0]]: decodeURIComponent([keyVal[1]]),
  });
}, {});

export default queryToParams;
