import React, { Fragment, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import MainSection from '../../../../components/MainSection';
import Tabs from '../Tabs';
import List from '../List';
import EmptyPage from '../EmptyPage';
import PlTabs from '../Placeholders/PlTabs';
import {
  isShowTabsSelector,
  searchResultSelector,
  searchTabsSelector,
  searchLoadingByTypeSelector,
} from '../../../../redux/search/selectors';
import { isTaskLoading } from '../../../../redux/loading/selectors';
import { SEARCH_LOADING } from '../../../../redux/loading/constants';
import { changeTab } from '../../../../redux/search/thunks';
import { $l } from '../../../../locales';

const SearchResult = () => {
  const dispatch = useDispatch();
  const isShowTabs = useSelector(isShowTabsSelector);
  const tabs = useSelector(searchTabsSelector);
  const result = useSelector(searchResultSelector);
  const loading = useSelector(state => isTaskLoading(state, SEARCH_LOADING));
  const loadingByType = useSelector(searchLoadingByTypeSelector);

  const changeTabHandler = useCallback((type) => {
    dispatch(changeTab(type));
  }, []);

  return (
    <Fragment>
      {loading && (
        <MainSection className="sp-offset--b--cm">
          <PlTabs />
        </MainSection>
      )}
      {!loading && isShowTabs && (
        <MainSection className="sp-offset--b--cm">
          <Tabs
            tabs={tabs}
            changeTabHandler={changeTabHandler}
            withoutSelect
          />
        </MainSection>
      )}
      <MainSection className="sp-offset--b--sm">
        <List items={result} loading={loading || loadingByType} hideItems={loading} />
      </MainSection>
      {!loadingByType && !result.length && !loading && (
        <MainSection>
          <EmptyPage
            icon="sp--colored--48--search"
            title={$l('SORRY_NOTHING_CAME_UP')}
            description={$l('TRY_WITH_DIFFERENT_SEARCH')}
            containerClasses="sp-container__inner--offset-t--md sp-container__inner--sm"
          />
        </MainSection>
      )}
    </Fragment>
  );
};

export default SearchResult;
