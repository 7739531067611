import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../../../../components/Icon';
import Text from '../../../../components/Text';

const ChatStatus = ({ name, status }) => (
  <div className="sp-ticket-status">
    <div className="sp-ticket-status__body">
      <div className="sp-ticket-status__title">
        <Text element="h3">
          {name}
        </Text>
      </div>
      {status && (
        <div className="sp-ticket-status__indicator">
          <span className="sp-status-indicator">
            <div className="sp-status-indicator__icon">
              <Icon id={status.icon} size="24" />
            </div>
            <div className="sp-status-indicator__title sp-status-indicator__title--rating">
              {status.text}
            </div>
          </span>
        </div>
      )}
    </div>
  </div>
);

ChatStatus.propTypes = {
  name: PropTypes.string.isRequired,
  status: PropTypes.shape({
    icon: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }),
};

ChatStatus.defaultProps = {
  status: null,
};

export default ChatStatus;
