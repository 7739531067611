import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../../../../components/Icon';
import Button from '../../../../components/Button';
import Text from '../../../../components/Text';
import SupportImage from '../SupportImage';
import messageHtml from '../../../../utils/messageHtml';

import { $l } from '../../../../locales';

const TicketHeader = ({ ticket, resolveTicket, isResolveLoading }) => (
  <div className="sp-help-center">
    <article className="sp-article">
      <header className="sp-article__header">
        <div className="sp-panel">
          <div className="sp-panel__body">
            <div className="sp-panel__author">
              <div className="sp-panel__icon">
                {ticket.initialMessageAvatar ? (
                  <SupportImage img={ticket.initialMessageAvatar} />
                ) : (
                  <Icon id="sp--colored--32--avatar" size="32" />
                )}
              </div>
              <div className="sp-panel__details">
                <div className="sp-panel__title">
                  <Text element="span" weight="bold">{ticket.initialMessage.author.name}</Text>
                </div>
                <div className="sp-panel__info">{ticket.createdAtFormatted}</div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="sp-article__description sp-offset--t--md">
        <p className="sp-text display-contents-link" dangerouslySetInnerHTML={messageHtml(ticket.name)} />
      </div>
      {!ticket.closedAt && (
        <div className="sp-article__control sp-offset--t--sm">
          <Button
            size="sm"
            empty
            className="sp-control--w--132"
            disabled={isResolveLoading}
            onClick={resolveTicket}
          >
            {$l('RESOLVE')}
          </Button>
        </div>
      )}
    </article>
  </div>
);

TicketHeader.propTypes = {
  ticket: PropTypes.shape({
    name: PropTypes.string.isRequired,
    initialMessageAvatar: PropTypes.string,
    initialMessage: PropTypes.shape({
      author: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    createdAtFormatted: PropTypes.string.isRequired,
    closedAt: PropTypes.string.isRequired,
  }).isRequired,
  resolveTicket: PropTypes.func.isRequired,
  isResolveLoading: PropTypes.bool.isRequired,
};

export default TicketHeader;
