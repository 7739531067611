import { $l } from '../../locales';

export const acceptedFileTypes = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/pdf',
  'image/png',
  'image/jpeg',
];

export const ORDERS = {
  asc: 'asc',
  desc: 'desc',
};

export const sortTicketOptions = () => [
  { id: ORDERS.desc, name: $l('NEWER') },
  { id: ORDERS.asc, name: $l('OLDER') },
];

export const tabs = () => [
  { type: 'tickets', title: $l('TICKETS') },
  { type: 'chats', title: $l('CHAT_HISTORY') },
];
