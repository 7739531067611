import React from 'react';

import Text from '../../../components/Text';
import Icon from '../../../components/Icon';
import Sidebar from '../components/Sidebar';
import SidebarSection from '../components/Sidebar/SidebarSection';
import CreateTicket from '../components/Sidebar/CreateTicket';
import Separator from '../components/Separator';
import { $l } from '../../../locales';
import ChatButton from '../components/Sidebar/ChatButton';

const SidebarRight = () => (
  <Sidebar position="right" className="sp-block--hide-900">
    <div className="sp-sidebar__inner">
      <ChatButton />
      <SidebarSection>
        <Text element="h3">{$l('CONTACT_US')}</Text>
      </SidebarSection>
      <SidebarSection className="sp-sidebar__section--offset--b-null">
        <a className="sp-control sp-control--primary sp-control--empty" href="tel:+1 (877) 389-0141">
          <span className="sp-control__body">
            <span className="sp-control__icon">
              <Icon id="sp--16--phone" />
            </span>
            <span className="sp-control__text">+1 (877) 389-0141</span>
          </span>
        </a>
      </SidebarSection>
      <Separator />
      <SidebarSection>
        <CreateTicket />
      </SidebarSection>
    </div>
  </Sidebar>
);

export default SidebarRight;
