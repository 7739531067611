import React from 'react';
import PropTypes from 'prop-types';

import Tab from './Tab';
import Action from './Action';

const Tabs = ({ tabs, changeTabHandler, withoutSelect }) => (
  <div className="sp-tabs" role="tablist">
    <div className="sp-tabs__body">
      <div className="sp-tabs__list">
        {tabs.map(el => (
          <Tab key={el.type} tab={el} changeTabHandler={changeTabHandler} />
        ))}
      </div>
      {!withoutSelect && <Action />}
    </div>
  </div>
);

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.object).isRequired,
  changeTabHandler: PropTypes.func.isRequired,
  withoutSelect: PropTypes.bool,
};

Tabs.defaultProps = {
  withoutSelect: false,
};

export default Tabs;
