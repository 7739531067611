import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import routes from '../../../../routes';

const RelatedTopics = ({ relatedTopics }) => (
  <ul className="sp-link-list">
    {relatedTopics.map(el => (
      <li key={el.id} className="sp-link-list__item">
        <Link to={`${routes.helpCenter}/${el.categorySlug}/${el.slug}/`} className="sp-link-list__link">
          {el.name}
        </Link>
      </li>
    ))}
  </ul>
);

RelatedTopics.propTypes = {
  relatedTopics: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    categorySlug: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
};

export default RelatedTopics;
