import React, { Fragment } from 'react';

import PlTabs from './PlTabs';
import PlItemList from './PlItemList';

const PlTickets = () => (
  <Fragment>
    <div className="sp-main__section sp-offset--b--cm">
      <div className="sp-breadcrumbs">
        <div className="sp-breadcrumbs__list">
          <div className="sp-breadcrumbs__item">
            <div className="sp-placeholder sp-placeholder--cm" />
            <div className="sp-placeholder-icon sp-placeholder-icon--14" />
          </div>
          <div className="sp-breadcrumbs__item">
            <div className="sp-placeholder sp-placeholder--cm" />
          </div>
        </div>
      </div>
    </div>
    <div className="sp-main__section sp-offset--t--cm sp-offset--b--sm">
      <div className="sp-text--h2">
        <div className="sp-placeholder" />
      </div>
    </div>
    <div className="sp-main__section sp-offset--b--cm">
      <PlTabs />
    </div>
    <div className="sp-main__section sp-offset--b--sm">
      <div className="sp-help-list">
        <PlItemList />
      </div>
    </div>
  </Fragment>
);

export default PlTickets;
