import { handleActions } from 'redux-actions';

import * as AC from './actions';

const initialState = {
  id: null,
  email: '',
  isLoaded: false,
};

export default handleActions(
  {
    [AC.UPDATE_USER]: (store, { payload }) => ({
      ...store, ...payload, isLoaded: true,
    }),
    [AC.RESET_USER]: (_) => ({
      ...initialState, isLoaded: true,
    }),
  },
  initialState,
);
