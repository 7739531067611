import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ModalContainer = ({ children, closeModal, mode }) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        closeModal();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [closeModal]);

  return (
    <div className="sp-modal-container">
      <div className="sp-modal-container__overlay" onClick={closeModal} />
      <div className={classNames('sp-modal', mode)}>
        {children}
      </div>
    </div>
  );
};

ModalContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  closeModal: PropTypes.func.isRequired,
  mode: PropTypes.string,
};

ModalContainer.defaultProps = {
  mode: null,
};

export default ModalContainer;
