import { $l } from '../../locales';

import { getDateToFormat, onlyDate } from '../../utils/toLocaleTime';
import { urlsValidation } from '../../utils/validations';

export const getRate = rate => {
  switch (rate) {
    case 1:
      return {
        icon: 'sp--colored--24--smile-1',
        text: $l('TERRIBLY'),
      };
    case 2:
      return {
        icon: 'sp--colored--24--smile-2',
        text: $l('BAD'),
      };
    case 3:
      return {
        icon: 'sp--colored--24--smile-3',
        text: $l('NORMAL'),
      };
    case 4:
      return {
        icon: 'sp--colored--24--smile-4',
        text: $l('GOOD'),
      };
    case 5:
      return {
        icon: 'sp--colored--24--smile-5',
        text: $l('EXCELLENT'),
      };
    default:
      return null;
  }
};

export const getDelimiter = (message, prevMessage) => {
  if (!prevMessage) return `${$l('CONVERSATION')} ${onlyDate(message.createdAt)}`;

  const messageDate = getDateToFormat(message.createdAt);
  const prevMessageDate = getDateToFormat(prevMessage.createdAt);

  const isEqualYear = messageDate.getFullYear() === prevMessageDate.getFullYear();
  const isEqualMonth = messageDate.getMonth() === prevMessageDate.getMonth();
  const isEqualDate = messageDate.getDate() === prevMessageDate.getDate();

  if (!isEqualYear || !isEqualMonth || !isEqualDate) return `${$l('CONVERSATION')} ${onlyDate(message.createdAt)}`;

  return '';
};

export const getCallbackMessage = callback => {
  if (callback.type === 'access_profile') {
    if (callback.answer) return `${$l('YOU')} ${$l('ALLOWED')} ${$l('ACCESS_TO_YOUR_PROFILE')} ${$l('UNTIL')} ${callback.validTo}`;

    return `${$l('YOU')} ${$l('CANCELED')} ${$l('ACCESS_TO_YOUR_PROFILE')}`;
  }

  if (callback.type === 'cancel_subscription') {
    return `${$l('YOU')} ${callback.answer ? $l('ALLOWED') : $l('CANCELED')} ${$l('CANCEL_SUBSCRIPTION')}`;
  }

  return '';
};

export const getImg = img => (urlsValidation(img) ? img : null);
