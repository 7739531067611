import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { resentTopicsSelector } from '../../../../redux/helpCenter/selectors';
import { isTaskLoading } from '../../../../redux/loading/selectors';
import { HELP_CENTER_LOADING } from '../../../../redux/loading/constants';
import PlHelpCenterRecentTopics from '../Placeholders/PlHelpCenterRecentTopics';
import MainSection from '../../../../components/MainSection';

const RecentTopics = () => {
  const resentTopics = useSelector(resentTopicsSelector);
  const loading = useSelector(state => isTaskLoading(state, HELP_CENTER_LOADING));

  if (loading && !resentTopics.length) return <PlHelpCenterRecentTopics />;

  return (
    <MainSection className="sp-main__section--offset-b--cm">
      <ul className="sp-link-list">
        {resentTopics.map(el => (
          <li key={el.id} className="sp-link-list__item">
            <Link to={el.link} className="sp-link-list__link">
              {el.name}
            </Link>
          </li>
        ))}
      </ul>
    </MainSection>
  );
};

export default RecentTopics;
