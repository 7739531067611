import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Icon from '../../../../components/Icon';
import Text from '../../../../components/Text';

function SmallCardItem({
  category: {
    name, icon, image, link,
  },
}) {
  return (
    <div className="sp-help-cards__item">
      <Link to={link} className="sp-help-card sp-help-card--link">
        <div className="sp-help-card__body">
          <div className="sp-help-card__section">
            <div className="sp-help-card__icon">
              {icon && icon.url ? (
                <img src={icon.url} alt="Category icon" width="32px" height="32px" />
              ) : (
                <Icon id={image} size="32" />
              )}
            </div>
            <div className="sp-help-card__title">
              <Text element="h3" as="h4">{name}</Text>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}

SmallCardItem.propTypes = {
  category: PropTypes.shape({
    name: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    link: PropTypes.string,
    icon: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
};

export default SmallCardItem;
