import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Icon = ({ id, size }) => (
  <span className={classNames('sp-icon', { [`sp-icon--size--${size}`]: size })} aria-hidden="true">
    <svg className="sp-icon__pic" aria-hidden="true" style={{ fill: 'currentColor' }}>
      <use xlinkHref={`#${id}`} />
    </svg>
  </span>
);

Icon.propTypes = {
  id: PropTypes.string.isRequired,
  size: PropTypes.string,
};

Icon.defaultProps = {
  size: '16',
};

export default Icon;
