import Echo from 'laravel-echo';

import config from './config';

require('pusher-js');

let connection = null;

export default async () => {
  try {
    const host = config.socketServer;
    const wsHost = host.replace(/(^\w+:|^)\/\//, '');

    const socketParams = {
      broadcaster: 'pusher',
      host,
      encrypted: true,
      namespace: 'App.Events',
      forceTLS: false,
      wsHost,
      wssHost: wsHost,
      key: config.pusherKey,
      authEndpoint: `${host}/api/broadcasting/auth/pws`,
      enabledTransports: ['wss', 'ws'],
    };

    if (connection) return connection;

    connection = new Echo(socketParams);

    return connection;
  } catch (e) {
    return null;
  }
};

export const getTicketsChannel = (userId, hash) => `support.uslegal.${userId}.${hash}`;
