import React from 'react';
import PropTypes from 'prop-types';

const NavList = ({ children }) => (
  <ul className="sp-nav__list">
    {children}
  </ul>
);

NavList.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

export default NavList;
