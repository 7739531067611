import React, {
  useEffect, useCallback, useState, Fragment,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import Breadcrumbs from '../components/Breadcrumbs';
import MainSection from '../../../components/MainSection';
import TicketStatus from '../components/Ticket/TicketStatus';
import TicketReply from '../components/Ticket/TicketReply';
import TicketHeader from '../components/Ticket/TicketHeader';
import TicketMessages from '../components/Ticket/TicketMessages';
import OpenAgain from '../components/Ticket/OpenAgain';
import PlTicket from '../components/Placeholders/PlTicket';
import Separator from '../components/Separator';
import EmptyPage from '../components/EmptyPage';

import { getTicket, resolveTicket } from '../../../redux/tickets/thunks';
import { ticketByIdSelector, placeholderTicketMessagesLengthSelector } from '../../../redux/tickets/selectors';
import { userIdSelector, isUserLoadedSelector } from '../../../redux/user/selectors';
import { isTaskLoading } from '../../../redux/loading/selectors';
import { RESOLVE_TICKET_LOADING, TICKET_LOADING } from '../../../redux/loading/constants';
import queryToParams from '../../../utils/queryToParams';
import routes from '../../../routes';
import { $l } from '../../../locales';
import config from '../../../config';

const Ticket = ({ match: { params }, history }) => {
  const dispatch = useDispatch();
  const [openAgain, setOpenAgain] = useState(false);
  const id = +params.id;
  const userId = useSelector(userIdSelector);
  const isUserLoaded = useSelector(isUserLoadedSelector);
  const ticket = useSelector(state => ticketByIdSelector(state, params.id));
  const isResolveLoading = useSelector(state => isTaskLoading(state, RESOLVE_TICKET_LOADING));
  const placeholderLength = useSelector(state => (
    placeholderTicketMessagesLengthSelector(state, params.id)
  ));
  const loading = useSelector(state => isTaskLoading(state, TICKET_LOADING));
  const name = `${$l('TICKET')}: ${id}`;
  const breadcrumbs = [
    { name: $l('MY_TICKETS'), link: `${routes.tickets}/` },
    { name },
  ];

  const { show: isShow } = queryToParams(history.location.search);

  const openAgainHandler = useCallback(() => {
    setOpenAgain(true);
  }, []);

  const goToLogin = useCallback(() => {
    window.location.href = `${config.USLegalFront}/login?redirect=${config.supportFront}/tickets/${id}/`;
  }, []);

  useEffect(() => {
    if (isUserLoaded) {
      if (!userId && !isShow) {
        goToLogin();
      }

      if (userId) {
        dispatch(getTicket(id));
      }
    }
  }, [isUserLoaded]);

  useEffect(() => {
    if (isResolveLoading && openAgain) {
      setOpenAgain(false);
    }
  }, [isResolveLoading]);

  const resolveTicketHandler = useCallback(
    () => dispatch(resolveTicket({ id, resolved: 1 })),
    [dispatch],
  );

  if (!isUserLoaded || loading || (isUserLoaded && !userId && !isShow)) return <PlTicket />;

  if (!ticket && userId) {
    return (
      <Fragment>
        <MainSection className="sp-offset--b--cm">
          <Breadcrumbs items={breadcrumbs} />
        </MainSection>
        <MainSection className="sp-offset--b--cm">
          {$l('TICKET_DOESNT_EXIT')}
        </MainSection>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <MainSection className="sp-offset--b--cm">
        <Breadcrumbs items={breadcrumbs} />
      </MainSection>
      {userId ? (
        <Fragment>
          <MainSection className="sp-offset--b--cm">
            <TicketStatus status={ticket.status} name={name} />
          </MainSection>
          <MainSection className="sp-offset--t--cm">
            <TicketHeader
              ticket={ticket}
              resolveTicket={resolveTicketHandler}
              isResolveLoading={isResolveLoading}
            />
          </MainSection>
          <MainSection>
            <Separator />
          </MainSection>
          <MainSection className="sp-offset--t--cm">
            <div className="sp-panel">
              <div className="sp-panel__body">
                <div className="sp-panel__section sp-panel__section--between sp-panel__section--v--center">
                  <h4 className="sp-text sp-text--h4">
                    {ticket.messages ? (
                      <Fragment>
                        {ticket.messages.length}
                        &nbsp;
                        {ticket.messages.length > 1 ? $l('MESSAGES') : $l('MESSAGE')}
                      </Fragment>
                    ) : (
                      <div className="sp-placeholder" />
                    )}
                  </h4>
                </div>
              </div>
            </div>
          </MainSection>
          <MainSection className="sp-offset--b--cm">
            {ticket.messages ? (
              <Fragment>
                {(ticket.closedAt && !openAgain) ? (
                  <OpenAgain openAgain={openAgainHandler} />
                ) : (
                  <TicketReply id={id} />
                )}
              </Fragment>
            ) : (
              <div className="sp-placeholder-note">
                <div className="sp-placeholder-control">
                  <div className="sp-text">
                    <div className="sp-placeholder" />
                  </div>
                </div>
              </div>
            )}
          </MainSection>
          <MainSection>
            <TicketMessages messages={ticket.messages} placeholderLength={placeholderLength} />
          </MainSection>
        </Fragment>
      ) : (
        <MainSection>
          <EmptyPage
            icon="sp--colored--48--document"
            title={$l('NO_TICKETS_LOGIN_TITLE')}
            description={$l('NO_TICKETS_LOGIN_DESCRIPTION')}
            buttonText={$l('LOGIN')}
            buttonHandler={goToLogin}
            containerClasses="sp-container__inner--offset-t--xl sp-container__inner--sm"
          />
        </MainSection>
      )}
    </Fragment>
  );
};

Ticket.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string.isRequired,
    }).isRequired,
    replace: PropTypes.func.isRequired,
  }).isRequired,
};

export default Ticket;
