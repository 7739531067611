import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from '../../../../components/Icon';

const TicketStatus = ({ status, name }) => (
  <div className="sp-ticket-status">
    <div className="sp-ticket-status__body">
      <div className="sp-ticket-status__title">
        <h3 className="sp-text sp-text--h3">{name}</h3>
      </div>
      <div className="sp-ticket-status__indicator">
        <span className="sp-status-indicator">
          <span className="sp-status-indicator__icon">
            <Icon id={status.icon} />
          </span>
          <span
            className={classNames('sp-status-indicator__title', {
              [`sp-status-indicator__title--${status.class}`]: status.class,
            })}
          >
            {status.title}
          </span>
        </span>
      </div>
    </div>
  </div>
);

TicketStatus.propTypes = {
  status: PropTypes.shape({
    icon: PropTypes.string.isRequired,
    class: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  name: PropTypes.string.isRequired,
};

export default TicketStatus;
