import React from 'react';
import PropTypes from 'prop-types';

const SupportImage = ({ img }) => <img alt="Avatar" className="sp-avatar" src={img} />;

SupportImage.propTypes = {
  img: PropTypes.string.isRequired,
};

export default SupportImage;
