import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form/dist/react-hook-form.ie11';
import classNames from 'classnames';

import { $l } from '../../../../locales';
import Button from '../../../../components/Button';
import Select from '../../../../components/Select';
import { isUserLoadedSelector, userEmailSelector } from '../../../../redux/user/selectors';
import { createTicket } from '../../../../redux/tickets/thunks';
import { selectCategory } from '../../../../redux/categoriesList/actions';
import { isTaskLoading } from '../../../../redux/loading/selectors';
import { selectedCategorySelector, categoriesSelector } from '../../../../redux/categoriesList/selectors';
import { CREATE_TICKET_LOADING } from '../../../../redux/loading/constants';
import { notify } from '../../../../redux/notifications/actions';
import { emailRegexp } from '../../../../constants/regexp';

const CreateTicket = () => {
  const dispatch = useDispatch();
  const [isErrorsExist, setErrorsExist] = useState(false);
  const loading = useSelector(state => isTaskLoading(state, CREATE_TICKET_LOADING));
  const userEmail = useSelector(userEmailSelector);
  const isUserLoaded = useSelector(isUserLoadedSelector);
  const selectedCategory = useSelector(selectedCategorySelector);
  const categories = useSelector(categoriesSelector);
  const {
    handleSubmit,
    register,
    errors,
    reset,
  } = useForm({ mode: 'onBlur' });

  useEffect(() => {
    const isErrors = Object.keys(errors).length > 0;
    setErrorsExist(isErrors);
  }, [Object.keys(errors).length]);

  const onSubmit = async ({ email, message }) => {
    try {
      await dispatch(createTicket({
        email,
        message,
        category: selectedCategory && selectedCategory.id,
        reload: !!userEmail,
      }));
      reset();
    } catch (e) {
      dispatch(notify.error({ title: $l('OOPS'), message: $l('SOMETHING_WENT_WRONG') }));
    }
  };

  const handleChange = useCallback((value) => {
    dispatch(selectCategory(value));
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="sp-form">
      <div className="sp-form__section">
        <div className="sp-form__item">
          <label className="sp-label" htmlFor="userEmail">{$l('YOUR_EMAIL')}</label>
          {isUserLoaded ? (
            <div className="sp-input-wrapper">
              <input
                id="userEmail"
                name="email"
                defaultValue={userEmail}
                ref={register({
                  required: $l('ENTER_EMAIL_ERROR'),
                  pattern: {
                    value: emailRegexp,
                    message: $l('CORRECT_EMAIL_ERROR'),
                  },
                })}
                disabled={!!userEmail}
                placeholder={$l('EMAIL_PLACEHOLDER')}
                className={classNames('sp-input sp-input--sm', { 'sp-is-invalid': errors.email })}
              />
            </div>
          ) : (
            <div className="sp-placeholder-input margin-top--0">
              <div className="sp-text sp-text--h4">
                <div className="sp-placeholder" />
              </div>
            </div>
          )}
          {errors.email && errors.email.message && (
            <div className="sp-subtext sp-subtext--error">{errors.email.message}</div>
          )}
        </div>
      </div>
      <div className="sp-form__section">
        <div className="sp-form__item">
          <label className="sp-label" htmlFor="userEmail">{$l('SELECT_A_QUESTION_CATEGORY')}</label>
          <Select
            value={selectedCategory}
            options={categories}
            onClick={handleChange}
          />
        </div>
      </div>
      <div className="sp-form__section">
        <div className="sp-form__item">
          <label className="sp-label" htmlFor="userProblem">{$l('YOUR_PROBLEM')}</label>
          <textarea
            id="userProblem"
            name="message"
            ref={register({
              required: true,
              validate: value => value.trim() !== '',
            })}
            className={classNames('sp-textarea', { 'sp-is-invalid': errors.message })}
            placeholder={$l('ASK_ANYTHING')}
          />
        </div>
      </div>
      <div className="sp-form__section">
        <Button type="submit" disabled={isErrorsExist || loading || !isUserLoaded}>
          {$l('SUBMIT_TICKET')}
        </Button>
      </div>
    </form>
  );
};

export default CreateTicket;
