import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// eslint-disable-next-line import/no-named-as-default,import/no-named-as-default-member
import ErrorBoundary from '../../containers/ErrorBoundary';
import TranslationsConnection from '../../containers/TranslationsConnection';
import PageByRoute from './PageByRoute';
import SidebarLeft from './containers/SidebarLeft';
import SidebarRight from './containers/SidebarRight';
import MainContent from './components/MainContent';
import PageMeta from './containers/PageMeta';

import { loadCategories, loadTopics } from '../../redux/helpCenter/thunks';
import { getUnreadTickets, connectTicketsSocket } from '../../redux/tickets/thunks';
import { getUser } from '../../redux/user/thunks';
import { loadCategoriesList } from '../../redux/categoriesList/thunks';
import { isUserLoadedSelector, loadedUserIdSelector } from '../../redux/user/selectors';
import { getTicketsHashSelector } from '../../redux/tickets/selectors';
import ChatSection from './containers/ChatSection';

const MainApp = () => {
  const dispatch = useDispatch();
  const loadedUserId = useSelector(loadedUserIdSelector);
  const isUserLoaded = useSelector(isUserLoadedSelector);
  const ticketsHash = useSelector(getTicketsHashSelector);

  useEffect(() => {
    dispatch(getUser());
    dispatch(loadCategories());
    dispatch(loadCategoriesList());
  }, []);

  useEffect(() => {
    if (isUserLoaded) dispatch(loadTopics());
  }, [isUserLoaded]);

  useEffect(() => {
    if (loadedUserId) dispatch(getUnreadTickets());
  }, [loadedUserId]);

  useEffect(() => {
    if (loadedUserId && ticketsHash) {
      dispatch(connectTicketsSocket({ userId: loadedUserId, ticketsHash }));
    }
  }, [loadedUserId, ticketsHash]);

  return (
    <ErrorBoundary>
      <TranslationsConnection>
        <div className="sp-grid__cellgroup" data-flex-grow="1">
          <div className="sp-grid__cell" data-flex-grow="1">
            <div className="sp-main">
              <PageMeta />
              <SidebarLeft />
              <MainContent>
                <PageByRoute />
              </MainContent>
              <SidebarRight />
              <ChatSection />
            </div>
          </div>
        </div>
      </TranslationsConnection>
    </ErrorBoundary>
  );
};

export default MainApp;
