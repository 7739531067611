import React from 'react';
import PropTypes, { string } from 'prop-types';
import { renderToStaticMarkup } from 'react-dom/server';
import classNames from 'classnames';

const rowRegular = /<\/?tr/g;
const rowMapper = (maches) => (
  maches.reduce((acc, el, index) => {
    const isNewRow = rowRegular.test(el[0]);

    if (isNewRow && acc[acc.length - 1].length) acc.push([]);

    if (el[4]) {
      acc[acc.length - 1].push({
        text: el[5].trim(),
        type: el[4],
      });
    }

    // clear last row if empty
    if (index === maches.length - 1 && !acc[acc.length - 1].length) acc.pop();
    return acc;
  }, [[]])
);

const RowElement = ({ rows, className }) => !!rows.length && (
  <div className={className}>
    {rows.map((row, rowIndex) => (
      <div key={rowIndex} className="sp-table__row">
        {!!row.length && row.map((item, itemIndex) => (
          <div
            key={itemIndex}
            className="sp-table__item"
            style={{ wordWrap: 'break-word' }}
            dangerouslySetInnerHTML={{ __html: item.text }}
          />
        ))}
      </div>
    ))}
  </div>
);

const isHeadRow = (row) => row.every(el => el.type === 'th');

RowElement.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.arrayOf(string),
  ).isRequired,
  className: PropTypes.string.isRequired,
};

export const tableTemplate = (table) => {
  const rowRegex = /(<tr.*?>)?((<(t[dh]).*?>([\w|\W]*?)<\/t[dh]>)|\s)+?/g;
  const bodyContent = Array.from(table.matchAll(rowRegex));

  const rows = rowMapper(bodyContent);

  const isColumnHeader = rows.every(el => el[0].type === 'th');
  const headRows = isColumnHeader ? [] : rows.filter(isHeadRow);

  return renderToStaticMarkup(
    <div className="sp-offset--b--sm" style={{ overflowX: 'auto' }}>
      <div
        className={classNames('sp-table', { 'sp-table--reverse': isColumnHeader })}
        style={{ width: 'fit-content', minWidth: '99%' }}
      >
        <RowElement rows={headRows} className="sp-table__header" />
        <RowElement rows={rows} className="sp-table__body" />
      </div>
    </div>,
  );
};

export const tableRender = (text) => (
  text.replace(/(<table.*?>[\w\W]*?<\/table>)/g, tableTemplate)
);
