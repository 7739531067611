import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from '../../../components/Icon';
import Button from '../../../components/Button';

const EmptyPage = ({
  icon, iconSize, title, description,
  buttonText, buttonHandler, containerClasses,
}) => (
  <div className="sp-container sp-container--centered">
    <div className={classNames('sp-container__inner', { [containerClasses]: containerClasses })}>
      <div className="sp-container__section sp-offset--b--sm">
        <Icon id={icon} size={iconSize} />
      </div>
      <div className="sp-container__section sp-offset--b--sm">
        <h3 className="sp-text sp-text--center sp-text--h3">
          {title}
        </h3>
      </div>
      <div className="sp-container__section sp-offset--b--md">
        <div className="sp-description">
          {description}
        </div>
      </div>
      {buttonHandler && (
        <div className="sp-container__section">
          <div className="sp-control-wrapper sp-control--w--200">
            <Button onClick={buttonHandler}>
              {buttonText}
            </Button>
          </div>
        </div>
      )}
    </div>
  </div>
);

EmptyPage.propTypes = {
  icon: PropTypes.string.isRequired,
  iconSize: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  buttonText: PropTypes.string,
  buttonHandler: PropTypes.func,
  containerClasses: PropTypes.string,
};

EmptyPage.defaultProps = {
  iconSize: '48',
  description: '',
  buttonText: '',
  buttonHandler: null,
  containerClasses: '',
};

export default EmptyPage;
