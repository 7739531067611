import { createAction } from 'redux-actions';

export const SET_TICKETS = 'TICKETS::LIST::SET';
export const SET_TICKET = 'TICKET::ITEM::SET';
export const RESET_TICKETS = 'TICKETS::RESET::SET';
export const SET_UNREAD_TICKETS = 'TICKETS::UNREAD::SET';
export const UPDATE_UNREAD_TICKETS = 'TICKETS::UNREAD::UPDATE';
export const CHANGE_TICKETS_SORT = 'TICKETS::SORT::CHANGE';
export const ATTACH_FILE = 'TICKETS::ATTACH::FILE';
export const UPDATE_ATTACHED_FILE = 'TICKETS::UPDATE::ATTACHED::FILE';
export const CLEAR_ATTACHED_FILES = 'TICKETS::CLEAR::ATTACHED::FILES';
export const DETACH_FILE = 'TICKETS::DETACH::FILE';
export const DETACH_FILE_BY_NAME = 'TICKETS::DETACH::FILE::BY::NAME';
export const CHANGE_ACTIVE_TAB = 'TICKETS::CHANGE_ACTIVE_TAB';

export const setTickets = createAction(SET_TICKETS);
export const setTicket = createAction(SET_TICKET);
export const resetTickets = createAction(RESET_TICKETS);
export const changeTicketsSort = createAction(CHANGE_TICKETS_SORT);
export const attachFile = createAction(ATTACH_FILE);
export const updateAttachedFile = createAction(UPDATE_ATTACHED_FILE);
export const clearAttachedFile = createAction(CLEAR_ATTACHED_FILES);
export const detachFile = createAction(DETACH_FILE);
export const detachFileByName = createAction(DETACH_FILE_BY_NAME);
export const setUnreadTickets = createAction(SET_UNREAD_TICKETS);
export const updateUnreadTickets = createAction(UPDATE_UNREAD_TICKETS);
export const changeActiveTab = createAction(CHANGE_ACTIVE_TAB);
