import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const SidebarSection = ({ className, children }) => (
  <div className={classNames('sp-sidebar__section', { [className]: className })}>
    {children}
  </div>
);

SidebarSection.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

SidebarSection.defaultProps = {
  className: '',
};

export default SidebarSection;
