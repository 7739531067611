import { createSelector } from 'reselect';
import routes from '../../routes';
import { isTaskLoading } from '../loading/selectors';
import { HELP_CENTER_LOADING } from '../loading/constants';

export const categoriesSelector = state => state.helpCenter.categories;
export const topicsSelector = state => state.helpCenter.topics;
export const getVoted = state => state.helpCenter.voted;
export const helpCenterLoading = state => isTaskLoading(state, HELP_CENTER_LOADING, true);

export const getCategoriesSelector = createSelector(
  categoriesSelector,
  categories => categories
    .map((el, index) => ({
      id: el.id,
      name: el.name,
      image: el.image,
      icon: el.icon,
      description: el.description,
      link: `${routes.helpCenter}/${el.slug}/`,
      slug: el.slug,
      isLast: index === categories.length - 1,
      sort: el.sort,
    }))
    .sort((a, b) => a.sort - b.sort),
);

export const relatedCategoriesSelector = createSelector(
  [categoriesSelector, (_, slug) => slug],
  (categories, slug) => categories
    .filter(el => el.slug !== slug)
    .map(el => ({
      id: el.id,
      name: el.name,
      image: el.image,
      icon: el.icon,
      link: `${routes.helpCenter}/${el.slug}/`,
    })),
);

export const getCategoryBySlugSelector = createSelector(
  [categoriesSelector, (_, slug) => slug],
  (categories, slug) => categories.find(el => el.slug === slug),
);

export const topicsToArraySelector = createSelector(
  topicsSelector,
  topics => Object.values(topics).reduce((acc, val) => acc.concat(val), []),
);

export const resentTopicsSelector = createSelector(
  topicsToArraySelector,
  topics => topics
    .sort((a, b) => (b.updatedAt >= a.updatedAt ? 1 : -1))
    .slice(-5)
    .map(el => ({
      id: el.id,
      name: el.name,
      link: `${routes.helpCenter}/${el.categorySlug}/${el.slug}/`,
    })),
);

export const topicsByCategorySelector = createSelector(
  [topicsSelector, (_, slug) => slug],
  (topics, slug) => (
    topics[slug] ? topics[slug].map(el => ({
      id: el.id,
      name: el.name,
      link: `${routes.helpCenter}/${el.categorySlug}/${el.slug}/`,
      sort: el.sort,
    })).sort((a, b) => a.sort - b.sort) : []
  ),
);

export const getTopicBySlugSelector = createSelector(
  [topicsToArraySelector, (_, slug) => slug],
  (topics, slug) => topics.find(el => el.slug === slug),
);

export const isClickedUsefulTopicSelector = createSelector(
  [getVoted, (_, slug) => slug],
  (voted, slug) => voted.includes(slug),
);

export const relatedTopicsSelector = createSelector(
  [topicsSelector, (_, slugs) => slugs],
  (topics, { topicSlug, categorySlug }) => {
    const categoryTopics = topics[categorySlug];

    if (!categoryTopics) return [];

    return topics[categorySlug].filter(el => el.slug !== topicSlug);
  },
);

export const getHelpCenterLoadingSelector = createSelector(
  [categoriesSelector, topicsToArraySelector, helpCenterLoading],
  (categories, topics, loading) => !categories.length && !topics.length && loading,
);
