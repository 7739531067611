import { handleActions } from 'redux-actions';

import * as AC from './actions';

const initialState = {
  chatsHistoryById: {},
  ids: [],
  pagination: {
    perPage: 10,
    total: 0,
  },
  isLoaded: false,
};

export default handleActions(
  {
    [AC.SET_CHATS_HISTORY]: (store, { payload: { chatsHistory, ...rest } }) => ({
      ...store,
      ...rest,
      chatsHistoryById: {
        ...store.chatsHistoryById,
        ...chatsHistory.reduce((obj, el) => (
          { ...obj, [el.id]: { ...store.chatsHistoryById[el.id], ...el } }
        ), {}),
      },
      ids: [
        ...store.ids,
        ...chatsHistory.map(el => el.id),
      ],
      isLoaded: true,
    }),
    [AC.SET_CHAT_HISTORY]: (store, { payload }) => ({
      ...store,
      chatsHistoryById: {
        ...store.chatsHistoryById,
        [payload.id]: { ...store.chatsHistoryById[payload.id], ...payload },
      },
    }),
    [AC.RESET_CHATS_HISTORY]: (store) => ({
      ...store,
      isLoaded: false,
      ids: [],
      pagination: {
        perPage: 10,
        total: 0,
      },
    }),
  },
  initialState,
);
