import { closeModal, openModal } from './actions';
import {
  CREATE_TICKET_MODAL, THANK_YOU_MODAL, REPLY_TICKET_MODAL, FULL_SIZE_IMAGE_MODAL,
} from './constants';

export const openCreateTicketModal = params => dispatch => (
  dispatch(openModal({ id: CREATE_TICKET_MODAL, params }))
);

export const closeCreateTicketModal = () => dispatch => (
  dispatch(closeModal(CREATE_TICKET_MODAL))
);

export const openThankYouModal = params => dispatch => (
  dispatch(openModal({ id: THANK_YOU_MODAL, params }))
);

export const closeThankYouModal = () => dispatch => (
  dispatch(closeModal(THANK_YOU_MODAL))
);

export const openReplyTicketModal = params => dispatch => (
  dispatch(openModal({ id: REPLY_TICKET_MODAL, params }))
);

export const closeReplyTicketModal = () => dispatch => (
  dispatch(closeModal(REPLY_TICKET_MODAL))
);

export const openFullSizeImageModal = params => dispatch => (
  dispatch(openModal({ id: FULL_SIZE_IMAGE_MODAL, params }))
);

export const closeFullSizeImageModal = () => dispatch => (
  dispatch(closeModal(FULL_SIZE_IMAGE_MODAL))
);
