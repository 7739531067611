import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';

import Categories from '../components/HelpCenterCategories/Categories';
import Breadcrumbs from '../components/Breadcrumbs';
import MainSection from '../../../components/MainSection';
import SearchInput from '../components/Search/SearchInput';
import Separator from '../components/Separator';
import Text from '../../../components/Text';
import PlHelpCenterCategories from '../components/Placeholders/PlHelpCenterCategories';

import { getHelpCenterLoadingSelector } from '../../../redux/helpCenter/selectors';
import { $l } from '../../../locales';

const HelpCenterCategories = () => {
  const loading = useSelector(getHelpCenterLoadingSelector);
  const breadcrumbs = [{ name: $l('HELP_CENTER') }];

  if (loading) return <PlHelpCenterCategories />;

  return (
    <Fragment>
      <MainSection className="sp-offset--b--cm">
        <Breadcrumbs items={breadcrumbs} />
      </MainSection>
      <MainSection className="sp-main__section--offset-t--cm sp-main__section--offset-b--md">
        <SearchInput />
      </MainSection>
      <MainSection className="sp-offset--b--sm">
        <Separator />
      </MainSection>
      <MainSection className="sp-offset--t--cm">
        <Text element="h1" as="h2">
          {$l('HELP_CENTER')}
        </Text>
      </MainSection>
      <MainSection className="sp-offset--b--sm sp-offset--t--xs">
        <Text>
          {$l('WELCOME_TO_US_LEGAL')}
        </Text>
      </MainSection>
      <Categories />
    </Fragment>
  );
};

export default HelpCenterCategories;
