import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../../../../components/Icon';
import Attachments from './Attachments';
import SupportImage from '../SupportImage';
import messageHtml from '../../../../utils/messageHtml';

const TicketMessage = ({ message }) => (
  <article className="sp-article sp-offset--t--md">
    <div className="sp-panel sp-offset--b--sm">
      <div className="sp-panel__body">
        <div className="sp-panel__author">
          <div className="sp-panel__icon">
            {message.avatar ? <SupportImage img={message.avatar} /> : <Icon id="sp--colored--32--avatar" size="32" />}
          </div>
          <div className="sp-panel__details">
            <div className="sp-panel__title">
              <span className="sp-text">{message.author.name}</span>
            </div>
            <div className="sp-panel__info">{message.createdAtFormatted}</div>
          </div>
        </div>
      </div>
    </div>
    <div className="sp-article__description sp-article__description--offset--l-md">
      <p className="sp-text display-contents-link" dangerouslySetInnerHTML={messageHtml(message.message)} />
      {message.attachments && (
        <div className="sp-simple-mesh">
          <Attachments attachments={message.attachments} canDownload />
        </div>
      )}
    </div>
  </article>
);

TicketMessage.propTypes = {
  message: PropTypes.shape({
    author: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    avatar: PropTypes.string,
    createdAtFormatted: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    attachments: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};
export default TicketMessage;
