import { createAction } from 'redux-actions';

import {
  ERROR_TYPE,
  INFO_TYPE,
  WARNING_TYPE,
  SUCCESS_TYPE,
} from './constants';

const classByType = {
  [ERROR_TYPE]: 'sp-topic--red',
  [INFO_TYPE]: 'sp-topic--light-blue',
  [SUCCESS_TYPE]: 'sp-topic--green',
  [WARNING_TYPE]: 'sp-topic--orange',
};

export const ADD_NOTIFICATION = 'NOTIFICATIONS/ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'NOTIFICATIONS/REMOVE_NOTIFICATION';
export const CLEAR_NOTIFICATIONS = 'NOTIFICATIONS/CLEAR_NOTIFICATIONS';

const generateId = () => +Math.random().toString().slice(2);

const isObject = (value) => value && typeof value === 'object';

export const createNotification = type => props => {
  if (isObject(props)) {
    const { title, message } = props;

    return {
      type,
      title,
      message,
      className: classByType[type],
      id: generateId(),
    };
  }

  return {
    type,
    message: props,
    className: classByType[type],
    id: generateId(),
  };
};

export const removeNotification = createAction(REMOVE_NOTIFICATION);
export const clearNotifications = createAction(CLEAR_NOTIFICATIONS);

export const notify = {
  error: createAction(ADD_NOTIFICATION, createNotification(ERROR_TYPE)),
  info: createAction(ADD_NOTIFICATION, createNotification(INFO_TYPE)),
  warning: createAction(ADD_NOTIFICATION, createNotification(WARNING_TYPE)),
  success: createAction(ADD_NOTIFICATION, createNotification(SUCCESS_TYPE)),
};
