const routes = {
  $root: '/',
  helpCenter: '/help-center',
  helpCenterCategory: '/help-center/:category',
  helpCenterTopic: '/help-center/:category/:topic',
  search: '/search',
  tickets: '/tickets',
  ticket: '/tickets/:id',
  chat: '/tickets/chat/:id',
};

export default routes;
