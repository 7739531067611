import React, {
  useEffect, useRef, useCallback, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form/dist/react-hook-form.ie11';
import classNames from 'classnames';

import Button from '../../../../components/Button';
import Attachments from './Attachments';
import { sendMessage, attachFileToMessage } from '../../../../redux/tickets/thunks';
import { clearAttachedFile, detachFile } from '../../../../redux/tickets/actions';
import {
  attachmentsSelector,
  isAttachmentsLoadingSelector,
  isMessageLoadingSelector,
} from '../../../../redux/tickets/selectors';
import { acceptedFileTypes } from '../../../../redux/tickets/constants';
import { notify } from '../../../../redux/notifications/actions';
import { $l } from '../../../../locales';
import {
  acceptedFileTypesForInput,
  checkSimilarName,
  newFileName,
  getFileAttachment,
} from '../../../../utils/attachments';

const TicketReply = ({ id }) => {
  const dispatch = useDispatch();
  const fileRef = useRef(null);
  const {
    handleSubmit, register, errors, reset,
  } = useForm();
  const [isErrorsExist, setErrorsExist] = useState(false);
  const attachments = useSelector(attachmentsSelector);
  const isAttachmentsLoading = useSelector(isAttachmentsLoadingSelector);
  const isMessageLoading = useSelector(state => isMessageLoadingSelector(state, id));

  useEffect(() => {
    const isErrors = Object.keys(errors).length > 0;
    setErrorsExist(isErrors);
  }, [Object.keys(errors).length]);

  useEffect(() => () => dispatch(clearAttachedFile()), []);

  const chooseFile = useCallback(() => fileRef && fileRef.current.click(), []);
  const remove = useCallback(fileId => dispatch(detachFile(fileId)), []);

  const attachFile = useCallback(({ target: { files } }) => {
    if (files.length) {
      const file = files[0];
      fileRef.current.value = '';

      if (file.size > 10485760) { // 10 MB
        dispatch(notify.error({ title: $l('OOPS'), message: $l('FILE_IS_TOO_BIG') }));
        return;
      }

      if (!acceptedFileTypes.includes(file.type)) return;

      const reader = new FileReader();

      const nameInAttachments = attachments && attachments
        .filter(el => (
          el.name === file.name.trim() || el.name.search(checkSimilarName(file.name)) !== -1
        ))
        .length;

      reader.onload = () => {
        dispatch(attachFileToMessage({
          file: reader.result,
          name: newFileName(file.name, nameInAttachments).trim(),
          extension: getFileAttachment(file.name),
          size: file.size,
        }));
      };

      reader.onabort = () => {
        dispatch(notify.error({ title: $l('OOPS'), message: $l('SOMETHING_WENT_WRONG') }));
      };
      reader.onerror = () => {
        dispatch(notify.error({ title: $l('OOPS'), message: $l('SOMETHING_WENT_WRONG') }));
      };
      reader.readAsDataURL(file);
    }
  }, [attachments]);

  const onSubmit = async ({ message }) => {
    try {
      await dispatch(sendMessage({
        id,
        message,
        attachments,
      }));

      reset();
      dispatch(clearAttachedFile());
    } catch (e) {
      dispatch(notify.error({ title: $l('OOPS'), message: $l('SOMETHING_WENT_WRONG') }));
    }
  };

  return (
    <div className="sp-note">
      <div className="sp-note__body">
        <div className="sp-note__message">
          <p className="sp-subtext">
            {$l('TICKET_REPLY_DESCRIPTION')}
          </p>
        </div>
        <input
          ref={fileRef}
          type="file"
          style={{ display: 'none' }}
          accept={acceptedFileTypesForInput}
          onChange={attachFile}
        />
        <form onSubmit={handleSubmit(onSubmit)} className="sp-form">
          <div className="sp-form__section">
            <div className={classNames('sp-form__item sp-form__item--textarea', { 'sp-is-invalid': errors.message })}>
              <div className="sp-form__textarea">
                <textarea
                  name="message"
                  ref={register({
                    required: true,
                    validate: value => value.trim() !== '',
                  })}
                  autoFocus
                  className="sp-textarea"
                  placeholder={$l('LET_US_KNOW_HOW_WE_CAN_HELP')}
                />
                <div className="sp-form__attachment">
                  <Attachments
                    attachments={attachments}
                    removeHandler={remove}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="sp-form__section">
            <div className="sp-form__actions">
              <Button
                size="sm"
                mode="transparent"
                icon="sp--16--attachment"
                className="sp-control--attachment"
                onClick={chooseFile}
              >
                {$l('ATTACH_FILE')}
              </Button>
              <Button
                type="submit"
                size="sm"
                className="sp-control--w--124 sp-control--offset--l"
                disabled={isMessageLoading || isAttachmentsLoading || isErrorsExist}
              >
                {$l('REPLY')}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

TicketReply.propTypes = {
  id: PropTypes.number.isRequired,
};

export default TicketReply;
