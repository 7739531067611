import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Sidebar from '../components/Sidebar';
import SidebarSection from '../components/Sidebar/SidebarSection';
import BottomNav from '../components/Sidebar/BottomNav';
import Nav from '../components/Nav';
import NavList from '../components/Nav/NavList';
import NavItem from '../components/Nav/NavItem';
import NavLink from '../components/Nav/NavLink';
import Text from '../../../components/Text';
import Icon from '../../../components/Icon';

import { getUnreadTicketsStateSelector } from '../../../redux/tickets/selectors';
import history from '../../../history';
import routes from '../../../routes';
import config from '../../../config';
import { $l } from '../../../locales';


let count = -1;

const newLogoLink = 'https://static-ak.pdffiller.com/static/support-static/258/images/uslegal/support/uslegal-logo2.png';

const SidebarLeft = () => {
  const unlisten = useRef(null);
  const isUnreadTickets = useSelector(getUnreadTicketsStateSelector);

  useEffect(() => {
    unlisten.current = history.listen(() => { count--; });

    return unlisten.current;
  }, [history.listen]);

  const goBack = () => { history.go(count); };

  return (
    <Sidebar position="left">
      <div className="sp-sidebar__inner">
        <SidebarSection className="sp-offset--b--cm sp-offset--t--cm">
          <div className="sp-panel">
            <div className="sp-panel__body">
              <div className="sp-panel__section sp-panel__section--between sp-panel__section--v--center">
                <button className="sp-link" onClick={goBack}>
                  <span className="sp-panel__section sp-panel__section--between sp-panel__section--v--center">
                    <span className="sp-icon sp-icon--size--16" aria-hidden="true">
                      <Icon id="sp--16--arrow-left" />
                    </span>
                  </span>
                </button>
                <a className="logo-link" href={`${config.USLegalFront}/`}>
                  <img alt="Logo" className="logo-img" src={newLogoLink} />
                </a>
              </div>
            </div>
          </div>
        </SidebarSection>
        <SidebarSection className="sp-offset--b--cm sp-offset--t--cm">
          <div className="sp-panel">
            <div className="sp-panel__body">
              <div className="sp-panel__section sp-panel__section--between sp-panel__section--v--center">
                <Link to={routes.$root} className="sp-link">
                  <Text element="h3" weight="bold" id="leftSidebarTitle">
                    {$l('SUPPORT')}
                  </Text>
                </Link>
              </div>
            </div>
          </div>
        </SidebarSection>
        <SidebarSection>
          <Nav>
            <NavList>
              <NavItem>
                <NavLink
                  to={`${routes.helpCenter}/`}
                  icon="sp--24--feedback"
                >
                  {$l('HELP_CENTER')}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to={`${routes.tickets}/`}
                  icon="sp--24--success"
                  notice={isUnreadTickets}
                >
                  {$l('MY_TICKETS')}
                </NavLink>
              </NavItem>
            </NavList>
          </Nav>
        </SidebarSection>
      </div>
      <BottomNav />
    </Sidebar>
  );
};

export default SidebarLeft;
