import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Text = ({
  element, as, weight, children,
}) => {
  const Tag = element;
  return (
    <Tag
      className={classNames('sp-text', {
        [`sp-text--${as || element}`]: element,
        [`sp-text--${weight}`]: weight,
      })}
    >
      {children}
    </Tag>
  );
};

Text.propTypes = {
  element: PropTypes.string,
  as: PropTypes.string,
  weight: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

Text.defaultProps = {
  element: 'p',
  as: '',
  weight: '',
};

export default Text;
