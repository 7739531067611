import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Tab = ({ tab, changeTabHandler }) => {
  const changeTab = () => {
    changeTabHandler(tab.type);
  };

  return (
    <div className="sp-tabs__item">
      <button
        className={classNames('sp-tabs__control', { 'is-active': tab.isActive })}
        style={{ width: '100%' }}
        role="tab"
        tabIndex="-1"
        disabled={tab.total === 0}
        onClick={changeTab}
      >
        <span className="sp-tabs__text">
          {tab.title}
          &nbsp;
        </span>
        {tab.total && (
          <span className="sp-tabs__text sp-tabs__text--count">
            (
            {tab.total}
            )
          </span>
        )}
      </button>
    </div>
  );
};

Tab.propTypes = {
  tab: PropTypes.shape({
    type: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    total: PropTypes.number,
  }).isRequired,
  changeTabHandler: PropTypes.func.isRequired,
};

export default Tab;
