import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Cards from '../Cards';
import SmallCardItem from '../Cards/SmallCardItem';
import MainSection from '../../../../components/MainSection';
import Text from '../../../../components/Text';
import { relatedCategoriesSelector } from '../../../../redux/helpCenter/selectors';
import { $l } from '../../../../locales';

const RelatedCategories = ({ categorySlug }) => {
  const relatedCategories = useSelector(state => relatedCategoriesSelector(state, categorySlug));

  return (
    <Fragment>
      <MainSection className="sp-offset--t--cm">
        <Text element="h2">{$l('RELATED_CATEGORIES')}</Text>
      </MainSection>
      <MainSection className="sp-offset--t--cm">
        <Cards>
          {relatedCategories.map(el => (
            <SmallCardItem key={el.id} category={el} />
          ))}
        </Cards>
      </MainSection>
    </Fragment>
  );
};

RelatedCategories.propTypes = {
  categorySlug: PropTypes.string.isRequired,
};

export default RelatedCategories;
