import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Icon from '../../../../../components/Icon';
import { $l } from '../../../../../locales';

const Attachments = ({ attachments, statusType }) => (
  <Fragment>
    {attachments.map(el => (
      <a
        key={el.id}
        href={el.url}
        download={el.name}
        className="sp-chat__attachment"
      >
        <div className="sp-chat__attachment-body">
          <div className="sp-chat__attachment-file">
            <div className="sp-chat__attachment-file-icon">
              <Icon id="24--file" size="24" />
            </div>
          </div>
          <div className="sp-chat__attachment-desc">
            <div className="sp-chat__attachment-desc-section">
              <div className="sp-chat__attachment-title">{el.name}</div>
            </div>
            <div className="sv-chat__attachment-desc-section">
              {statusType === 'supporter' && (
                <span className="sp-chat__attachment-subtitle sp-chat__attachment-subtitle--download">
                  {$l('CLICK_TO_DOWNLOAD')}
                </span>
              )}
              <span className="sp-chat__attachment-subtitle">{el.size}</span>
            </div>
          </div>
        </div>
      </a>
    ))}
  </Fragment>
);

Attachments.propTypes = {
  attachments: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    url: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    extension: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
  })).isRequired,
  statusType: PropTypes.string.isRequired,
};

export default Attachments;
