import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { initLocales } from '../locales';

function TranslationsConnection({ children }) {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    initLocales(() => {
      setReady(true);
    });
  }, []);

  return ready && children;
}

TranslationsConnection.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

export default TranslationsConnection;
