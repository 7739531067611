import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import Icon from '../../../components/Icon';
import PlItemList from './Placeholders/PlItemList';

const List = ({ items, loading, hideItems }) => (
  <ul className="sp-help-list">
    {!hideItems && items.map(el => (
      <li key={el.id || el.slug} className="sp-help-list__item">
        <span className="sp-help-list__desc">
          <Link to={el.link} className="sp-link sp-text--semibold">
            <span className="sp-help-list__title">
              {el.title}
            </span>
            {el.isActive && <span className="sp-help-list__status" />}
          </Link>
          <span className="sp-help-list__subtitle">
            {el.description}
          </span>
        </span>
        {el.status && (
          <span className="sp-status-indicator">
            <span className="sp-status-indicator__icon">
              <Icon id={el.status.icon} />
            </span>
            <span
              className={classNames('sp-status-indicator__title', {
                [`sp-status-indicator__title--${el.status.class}`]: el.status.class,
              })}
            >
              {el.status.title}
            </span>
          </span>
        )}
        {el.rate && (
          <span className="sp-status-indicator">
            <span className="sp-status-indicator__icon">
              <Icon id={el.rate.icon} size="24" />
            </span>
            <span className="sp-status-indicator__title sp-status-indicator__title--rating">
              {el.rate.text}
            </span>
          </span>
        )}
        {el.emptyRate && <span />}
      </li>
    ))}
    {loading && <PlItemList />}
  </ul>
);

List.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  hideItems: PropTypes.bool,
};

List.defaultProps = {
  hideItems: false,
};

export default List;
