import React from 'react';
import PropTypes from 'prop-types';

import messageHtml from '../../../../../utils/messageHtml';

const Message = ({ message }) => (
  <div
    className="sp-chat__message display-contents-link"
    dangerouslySetInnerHTML={messageHtml(message)}
  />
);

Message.propTypes = {
  message: PropTypes.string.isRequired,
};

export default Message;
