import React from 'react';
import PropTypes from 'prop-types';

function DownloadButton({ url, name, children }) {
  return (
    <a
      className="sp-attachment sp-attachment--link"
      href={url}
      download={name}
    >
      {children}
    </a>
  );
}

DownloadButton.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

function Details({ children }) {
  return (
    <div className="sp-attachment">
      {children}
    </div>
  );
}

Details.propTypes = {
  children: PropTypes.node.isRequired,
};

function AttachmentContainer({
  canDownload, url, name, children,
}) {
  return (canDownload && url) ? (
    <DownloadButton url={url} name={name}>{children}</DownloadButton>
  ) : (
    <Details>{children}</Details>
  );
}

AttachmentContainer.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  canDownload: PropTypes.bool,
  url: PropTypes.string,
};

AttachmentContainer.defaultProps = {
  canDownload: false,
  url: '',
};

export default AttachmentContainer;
