import { handleActions } from 'redux-actions';

import * as AC from './actions';

const initialState = {
  categories: [],
  selectedCategory: {},
};

export default handleActions(
  {
    [AC.SET_CATEGORIES]: (store, { payload }) => ({
      ...store,
      categories: payload,
    }),
    [AC.SELECT_CATEGORY]: (store, { payload }) => ({
      ...store,
      selectedCategory: payload,
    }),
  },
  initialState,
);
