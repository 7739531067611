import api from '../../api';
import { setCookie, getCookie, deleteCookie } from '../../utils/cookies';
import { setExperiment } from './actions';
import { DEFAULT_BRANCH, PREFIX_BRANCH } from '../../constants/experiment';
import { getUserID } from '../../utils/userGuestId';
import { emailValidation } from '../../utils/validations';

const twoHoursInSeconds = new Date(new Date().setUTCSeconds(7200));

export const getExperimentAction = (expName) => async dispatch => {
  try {
    const expCookieBranch = getCookie(PREFIX_BRANCH + expName);
    deleteCookie(expName);

    if (!expCookieBranch) {
      const { branch } = await api.get(`fortune/${expName}`);
      const expBranch = branch || DEFAULT_BRANCH;

      setCookie(PREFIX_BRANCH + expName, expBranch, {
        expires: twoHoursInSeconds,
      });
      dispatch(setExperiment({ [expName]: expBranch }));
    } else {
      dispatch(setExperiment({ [expName]: expCookieBranch }));
    }
  } catch (err) {
    setCookie(expName, DEFAULT_BRANCH);
    dispatch(setExperiment({ [expName]: DEFAULT_BRANCH }));
  }
};

export const trackEvents = (eventName, data = {}) => async (dispatch, getState) => {
  try {
    const { user } = getState();
    const userEmail = emailValidation(user.email) ? user.email : null;
    const guestId = userEmail || user.id || getUserID();

    await api.post(`track-event/${eventName}`, {
      ...data,
      guest_id: guestId,
    });
  } catch (err) {
    //
  }
};
