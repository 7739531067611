import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Attachment from './Attachment';

const Attachments = ({ attachments, removeHandler, canDownload }) => (
  <Fragment>
    {attachments.map(el => (
      <Attachment
        key={el.name}
        attachment={el}
        removeHandler={removeHandler}
        canDownload={canDownload}
      />
    ))}
  </Fragment>
);

Attachments.propTypes = {
  attachments: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  removeHandler: PropTypes.func,
  canDownload: PropTypes.bool,
};

Attachments.defaultProps = {
  removeHandler: null,
  canDownload: false,
};

export default Attachments;
