import { createSelector } from 'reselect';

export const getNotifications = state => state.notifications;

export const notificationsSelector = createSelector(
  getNotifications,
  notifications => notifications.map((el, index) => ({
    ...el,
    top: `${index * 90 + 20}px`,
  })),
);
