import React from 'react';
import PropTypes from 'prop-types';

const Cards = ({ children }) => (
  <div className="sp-help-cards">
    <div className="sp-help-cards__body">
      {children}
    </div>
  </div>
);

Cards.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

export default Cards;
