import { handleActions } from 'redux-actions';

import * as AC from './actions';
import { types } from './constants';

const initialState = {
  value: '',
  countValue: '',
  counts: [],
  result: {},
  selectedType: types()[0].slug,
};

// {
//   page: 0,
//   data: [],
//   totalPages: 3,
//   loading: false,
// }

const ensureArrayUpdate = (data = [], updateData = []) => ([...data, ...updateData]);

export default handleActions(
  {
    [AC.SET_SEARCH_VALUE]: (store, { payload }) => ({
      ...store, value: payload,
    }),
    [AC.SET_COUNT_VALUE]: (store, { payload }) => ({
      ...store, countValue: payload,
    }),
    [AC.SET_SEARCH_COUNTS]: (store, { payload }) => ({
      ...store, counts: payload,
    }),
    [AC.SET_SEARCH_RESULT]: (store, { payload }) => ({
      ...store, result: payload,
    }),
    [AC.UPDATE_SEARCH_RESULT]: (store, { payload: { type, ...rest } }) => ({
      ...store,
      result: {
        ...store.result,
        [type]: {
          ...store.result[type],
          ...rest,
          data: ensureArrayUpdate(store.result[type]?.data, rest.data),
        },
      },
    }),
    [AC.SET_SEARCH_TYPE]: (store, { payload }) => ({
      ...store, selectedType: payload,
    }),
    [AC.RESET_SEARCH]: () => ({
      ...initialState,
    }),
  },
  initialState,
);
