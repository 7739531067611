import React, { useState, useCallback, useRef } from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import SidebarSection from './SidebarSection';
import Icon from '../../../../components/Icon';
import useOutsideClick from '../../../../hooks/useOutsideClick';
import { userIdSelector } from '../../../../redux/user/selectors';
import config from '../../../../config';
import { $l } from '../../../../locales';

const BottomNav = () => {
  const [open, toggle] = useState(false);
  const ref = useRef(null);
  const userId = useSelector(userIdSelector);
  const { pathname } = useLocation();

  const toggleHandler = useCallback(() => {
    toggle(prevState => !prevState);
  }, [open]);

  const outsideClickHandler = useCallback(() => {
    if (open) toggle(false);
  }, [open]);

  useOutsideClick(ref, outsideClickHandler);

  return (
    <div className="sp-sidebar__inner sp-sidebar__inner--bottom">
      <SidebarSection className="sp-offset--b--md">
        <div className="sp-simple-mesh sp-simple-mesh--v-center">
          {userId && (
            <div className="sp-sidebar__item">
              <a
                className="sp-control sp-control--transparent sp-control--small"
                href={`${config.USLegalFront}/logout/?redirect=${config.supportFront}${pathname}`}
              >
                <Icon id="sp--24--logout" size="24" />
              </a>
            </div>
          )}
          <div className="sp-sidebar__item">
            <div className="sp-sidebar-select" ref={ref}>
              <button
                className="sp-control sp-control--transparent sp-control--small"
                onClick={toggleHandler}
              >
                <Icon id="sp--24--more" size="24" />
              </button>
              <div className={classNames('sp-sidebar-select__popup', { 'sp-sidebar-select__popup--open': open })}>
                <div className="sp-sidebar-select__popup-body">
                  <a
                    href={`${config.USLegalFront}/pricing/`}
                    target="_blank"
                    className="sp-sidebar-select__popup-item"
                  >
                    {$l('PRICING')}
                  </a>
                  <a
                    href={`${config.USLegalFront}/terms-of-service/`}
                    target="_blank"
                    className="sp-sidebar-select__popup-item"
                  >
                    {$l('TERM_OF_SERVICE')}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="sp-sidebar__item">
            <a
              href={`${config.USLegalFront}/privacy-policy/`}
              target="_blank"
              className="sp-control sp-control--small"
            >
              <div className="sp-text">{$l('PRIVACY')}</div>
            </a>
          </div>
        </div>
      </SidebarSection>
    </div>
  );
};

export default BottomNav;
