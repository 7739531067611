import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import ModalContainer from './ModalContainer';
import Icon from '../../../../components/Icon';
import Text from '../../../../components/Text';
import Button from '../../../../components/Button';

import { closeThankYouModal } from '../../../../redux/modals/thunks';
import { userIdSelector } from '../../../../redux/user/selectors';

import routes from '../../../../routes';
import { $l } from '../../../../locales';
import config from '../../../../config';

const ThankYou = ({ ticketId }) => {
  const dispatch = useDispatch();
  const userId = useSelector(userIdSelector);

  const closeModal = useCallback(() => {
    dispatch(closeThankYouModal());
  }, []);

  const buttonHandler = useCallback(() => {
    if (userId) {
      closeModal();
    } else {
      window.location.href = `${config.USLegalFront}/login/?redirect=${config.supportFront}/tickets/${ticketId}/`;
    }
  }, [ticketId, userId]);

  const BUTTON_TEXT = useMemo(() => (userId ? $l('GOT_IT') : $l('LOGIN')), [userId]);
  const ticketLink = useMemo(
    () => `${routes.tickets}/${ticketId}/${!userId ? '?show=true' : ''}`,
    [userId, ticketId],
  );

  return (
    <ModalContainer closeModal={closeModal}>
      <button type="button" className="sp-modal__close" onClick={closeModal}>
        <Icon id="sp--16--close-small" />
      </button>
      <div className="sp-modal__body">
        <div className="sp-modal__body-inner">
          <div className="sp-modal__icon">
            <Icon id="sp--colored--48--success" size="48" />
          </div>
          <div className="sp-modal__title">
            <Text element="h3">{$l('THANKS')}</Text>
          </div>
          <div className="sp-modal__section sp-offset--t--sm">
            <div className="sp-note" style={{ width: 'auto' }}>
              <div className="sp-note__body">
                <div className="sp-text">
                  {$l('YOUR_TICKET_NUMBER')}
                  &nbsp;
                  <Link
                    to={ticketLink}
                    onClick={closeModal}
                    className="sp-link sp-link--underline"
                  >
                    #
                    {ticketId}
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="sp-modal__section sp-offset--b--cm">
            <div className="sp-description sp-offset--t--sm">
              {$l('THANK_YOU_MODAL_DESCRIPTION_START')}
            </div>
            <div className="sp-description sp-offset--t--sm">
              {$l('THANK_YOU_MODAL_DESCRIPTION_END')}
            </div>
          </div>
          <div className="sp-modal__control sp-offset--t--cm">
            <Button className="sp-control--w--132" onClick={buttonHandler}>
              {BUTTON_TEXT}
            </Button>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

ThankYou.propTypes = {
  ticketId: PropTypes.number,
};

ThankYou.defaultProps = {
  ticketId: null,
};

export default ThankYou;
