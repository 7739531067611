import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import ModalContainer from './ModalContainer';
import Icon from '../../../../components/Icon';

import { closeFullSizeImageModal } from '../../../../redux/modals/thunks';

const FullSizeImage = ({ imageLink }) => {
  const dispatch = useDispatch();

  const closeModal = useCallback(() => {
    dispatch(closeFullSizeImageModal());
  }, []);

  return (
    <ModalContainer mode="has-media" closeModal={closeModal}>
      <button type="button" className="sp-modal__close" onClick={closeModal}>
        <Icon id="sp--16--close-small" />
      </button>
      <div className="sp-modal__body">
        <div className="sp-modal__body-inner">
          <img className="sp-modal__img" src={imageLink} alt="Full size" />
        </div>
      </div>
    </ModalContainer>
  );
};

FullSizeImage.propTypes = {
  imageLink: PropTypes.string,
};

FullSizeImage.defaultProps = {
  imageLink: null,
};

export default FullSizeImage;
