import React from 'react';

const PlTicketMessage = () => (
  <div className="sp-article">
    <div className="sp-article__header sp-offset--b--cm">
      <div className="sp-panel">
        <div className="sp-panel__body">
          <div className="sp-panel__author">
            <div className="sp-panel__icon">
              <div className="sp-placeholder-icon sp-placeholder-icon--32" />
            </div>
            <div className="sp-panel__details">
              <div className="sp-panel__title sp-offset--b--cm">
                <div className="sp-placeholder sp-placeholder--sm" />
              </div>
              <div className="sp-panel__info">
                <div className="sp-placeholder sp-placeholder--cm" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="sp-article__description">
      <div className="sp-text">
        <div className="sp-placeholder" />
        <div className="sp-placeholder sp-placeholder--50" />
      </div>
    </div>
    <div className="sp-article__description">
      <div className="sp-text">
        <div className="sp-placeholder" />
        <div className="sp-placeholder sp-placeholder--50" />
      </div>
    </div>
  </div>
);

export default PlTicketMessage;
