import { $l } from '../locales';

const helpCenterMeta = {
  helpCenter: {
    getTitle: () => $l('META_HELP_CENTER_TITLE'),
    getDescription: () => $l('META_HELP_CENTER_DESCRIPTION'),
  },
  myAccount: {
    getTitle: () => $l('META_MY_ACCOUNT_TITLE'),
    getDescription: () => $l('META_MY_ACCOUNT_DESCRIPTION'),
  },
  myAccountInnerPages: {
    getTitle: text => `${text} ${$l('META_INNER_PAGES_TITLE')}`,
    getDescription: text => `${text} ${$l('META_INNER_PAGES_DESCRIPTION')}`,
  },
  billing: {
    getTitle: () => $l('META_BILLING_TITLE'),
    getDescription: () => $l('META_BILLING_DESCRIPTION'),
  },
  billingInnerPages: {
    getTitle: text => `${text} ${$l('META_INNER_PAGES_TITLE')}`,
    getDescription: text => `${text} ${$l('META_INNER_PAGES_DESCRIPTION')}`,
  },
  forms: {
    getTitle: () => $l('META_FORMS_TITLE'),
    getDescription: () => $l('META_FORMS_DESCRIPTION'),
  },
  formsInnerPages: {
    getTitle: text => `${text} ${$l('META_INNER_PAGES_TITLE')}`,
    getDescription: text => `${text} ${$l('META_INNER_PAGES_DESCRIPTION')}`,
  },
  faqs: {
    getTitle: () => $l('META_FAQS_TITLE'),
    getDescription: () => $l('META_FAQS_DESCRIPTION'),
  },
  faqsInnerPages: {
    getTitle: text => `${text} ${$l('META_INNER_PAGES_TITLE')}`,
    getDescription: text => `${text} ${$l('META_INNER_PAGES_DESCRIPTION')}`,
  },
};

const defaultMeta = () => ({
  title: $l('META_DEFAULT_TITLE'),
  description: $l('META_DEFAULT_DESCRIPTION'),
});

const keyByCategorySlug = {
  'my-account': 'myAccount',
  'billing-legal': 'billing',
  'forms-categories-and-search': 'forms',
  faqs: 'faqs',
};

const getMetaInformationForPage = (topic, categorySlug, isHelpCenter) => {
  const defaultCategory = 'helpCenter';
  const categoryKey = keyByCategorySlug[categorySlug];

  if (!isHelpCenter) return defaultMeta();

  if (topic) {
    const metaKey = categoryKey ? `${categoryKey}InnerPages` : defaultCategory;
    const { getTitle, getDescription } = helpCenterMeta[metaKey];
    const title = getTitle(topic.name);
    const description = getDescription(topic.name);

    return { title, description };
  }

  const { getTitle, getDescription } = helpCenterMeta[categoryKey || defaultCategory];
  const title = getTitle();
  const description = getDescription();

  return { title, description };
};

export default getMetaInformationForPage;
