import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import Icon from '../../../../components/Icon';
import NotificationIcon from './NotificationIcon';
import { removeNotification } from '../../../../redux/notifications/actions';
import { DEFAULT_NOTIFICATION_TIMEOUT } from '../../../../redux/notifications/constants';

const Notification = ({
  notification: {
    id, type, title, message, className, top,
  },
}) => {
  const dispatch = useDispatch();
  const timer = useRef(null);

  const closeHandler = () => dispatch(removeNotification(id));

  const cancelAutoCloseProcess = () => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
  };

  const initAutoCloseProcess = () => {
    timer.current = setTimeout(
      () => closeHandler(),
      DEFAULT_NOTIFICATION_TIMEOUT,
    );
  };

  useEffect(() => {
    initAutoCloseProcess();
    return () => cancelAutoCloseProcess();
  }, []);

  return (
    <div
      className={classNames('sp-topic', className)}
      style={{ top }}
      onMouseEnter={cancelAutoCloseProcess}
      onMouseLeave={initAutoCloseProcess}
    >
      <div className="sp-topic__body">
        <div className="sp-topic__icon">
          <NotificationIcon type={type} />
        </div>
        <div className="sp-topic__desc">
          <div className="sp-topic__title">{title}</div>
          <div className="sp-topic__subtitle">{message}</div>
        </div>
        <button className="sp-topic__close" onClick={closeHandler}>
          <Icon id="sp--colored--16--close" />
        </button>
      </div>
    </div>
  );
};

Notification.propTypes = {
  notification: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,
    top: PropTypes.string.isRequired,
  }).isRequired,
};

export default Notification;
