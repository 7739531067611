import configStore from '../../../redux/configStore';
import middlewares from '../../../redux/middlewares';
import stateSaver from '../../../utils/stateSaver';
import reducers from './reducers';

const store = configStore(
  reducers,
  middlewares,
  stateSaver.getInitialState(),
);

stateSaver.clear();
stateSaver.listen(store);

export default store;
