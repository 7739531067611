import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import MainSection from '../../../../components/MainSection';
import Text from '../../../../components/Text';
import { topicsByCategorySelector } from '../../../../redux/helpCenter/selectors';

const Category = ({ category, onlyOne }) => {
  const topics = useSelector(state => topicsByCategorySelector(state, category.slug));

  return (
    <Fragment>
      <MainSection className="sp-offset--b--sm sp-offset--t--md">
        <Text element={onlyOne ? 'h1' : 'h2'} as="h3">{category.name}</Text>
      </MainSection>
      <MainSection className="sp-offset--b--sm">
        <Text>{category.description}</Text>
      </MainSection>
      <MainSection className="sp-offset--b--sm">
        <ul className="sp-link-list sp-link-list--theme--rows-text">
          {topics.map(el => (
            <li key={el.id} className="sp-link-list__item">
              <Link to={el.link} className="sp-link-list__link">
                {el.name}
              </Link>
            </li>
          ))}
        </ul>
      </MainSection>
    </Fragment>
  );
};

Category.propTypes = {
  category: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  }).isRequired,
  onlyOne: PropTypes.bool,
};

Category.defaultProps = {
  onlyOne: false,
};

export default Category;
