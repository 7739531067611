import * as Sentry from '@sentry/browser';

import { uslInstance } from '../../api';
import { updateUser, resetUser } from './actions';

const USLegalInstanceConfig = {
  headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
  withCredentials: true,
  data: {},
};

export const getUser = () => async dispatch => {
  try {
    const { isAuthorized } = await uslInstance.get('user/authorized', USLegalInstanceConfig);

    if (isAuthorized) {
      const { id, email } = await uslInstance.get('user', USLegalInstanceConfig);

      dispatch(updateUser({ id, email, isLoaded: true }));
      Sentry.setUser({ id, email });
    } else {
      dispatch(resetUser());
    }
  } catch (e) {
    dispatch(resetUser());
  }
};
