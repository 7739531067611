import api from '../../api';
import {
  setCategories, setTopics, updateTopic, voteTopic,
} from './actions';
import { loadingThunk } from '../loading/thunks';
import { HELP_CENTER_LOADING } from '../loading/constants';
import { tableRender } from '../../utils/topicTemplate';
import { getUserID } from '../../utils/userGuestId';
import { emailValidation } from '../../utils/validations';

export const loadCategoriesAction = () => async dispatch => {
  try {
    const { data } = await api.get('help-center/category', { params: { per_page: 1000 } });

    const res = data.sort((a, b) => a.sort - b.sort);

    dispatch(setCategories(res));
  } catch (e) {
    // console.log(e);
  }
};

export const loadCategories = loadingThunk(HELP_CENTER_LOADING)(loadCategoriesAction);

export const loadTopicsAction = () => async (dispatch, getState) => {
  try {
    const { user } = getState();
    const userEmail = emailValidation(user.email) ? user.email : null;
    const guestId = userEmail || user.id || getUserID();

    const { data } = await api.get('help-center/topic', { params: { exp_guest_id: guestId } });

    const res = data.reduce((acc, el) => {
      const topic = {
        ...el,
        description: tableRender(el.description),
      };
      const category = acc[topic.categorySlug];
      acc[el.categorySlug] = category ? [...category, topic] : [topic];
      return acc;
    }, {});

    dispatch(setTopics(res));
  } catch (e) {
    // console.log(e);
  }
};

export const loadTopics = loadingThunk(HELP_CENTER_LOADING)(loadTopicsAction);

export const sendUsefulTopic = ({ topic, flag }) => async dispatch => {
  try {
    const {
      id, slug, useful, usefulClicks, categorySlug,
    } = topic;

    dispatch(updateTopic({
      id,
      useful: +flag + useful,
      usefulClicks: usefulClicks + 1,
      categorySlug,
    }));
    dispatch(voteTopic(slug));

    const voted = JSON.parse(localStorage.getItem('support-voted-topics')) || [];
    localStorage.setItem('support-voted-topics', JSON.stringify([...voted, slug]));

    await api.put(`help-center/topic/${slug}/useful`, { flag });
  } catch (e) {
    // console.log(e);
  }
};
