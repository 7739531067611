const lowerCase = str => str.toLowerCase();
const upperCase = str => str.toUpperCase();

const isArray = arr => Array.isArray(arr);
const isObject = (obj) => obj === Object(obj) && !isArray(obj) && typeof obj !== 'function';

export const toCamelCase = str => str
  .replace(/[\s_-](.)/g, upperCase)
  .replace(/[\s_-]/g, '')
  .replace(/^(.)/, lowerCase);

const toCamelCaseRecursive = obj => {
  if (isObject(obj)) {
    const n = {};

    Object.keys(obj)
      .forEach((k) => {
        n[toCamelCase(k)] = toCamelCaseRecursive(obj[k]);
      });

    return n;
  }

  if (isArray(obj)) {
    return obj.map((i) => toCamelCaseRecursive(i));
  }

  return obj;
};

export default toCamelCaseRecursive;
