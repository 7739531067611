import helpCenter from '../../../redux/helpCenter';
import loading from '../../../redux/loading';
import search from '../../../redux/search';
import helper from '../../../redux/helper';
import tickets from '../../../redux/tickets';
import chatsHistory from '../../../redux/chatsHistory';
import user from '../../../redux/user';
import modals from '../../../redux/modals';
import notifications from '../../../redux/notifications';
import categoriesList from '../../../redux/categoriesList';
import experiments from '../../../redux/experiments';

const reducers = {
  helpCenter,
  loading,
  search,
  helper,
  tickets,
  chatsHistory,
  user,
  modals,
  notifications,
  categoriesList,
  experiments,
};

export default reducers;
