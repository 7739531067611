import React from 'react';
import { useSelector } from 'react-redux';

import Cards from '../Cards';
import CardItem from '../Cards/CardItem';
import PlHelpCenterCards from '../Placeholders/PlHelpCenterCards';
import { getCategoriesSelector } from '../../../../redux/helpCenter/selectors';
import { isTaskLoading } from '../../../../redux/loading/selectors';
import { HELP_CENTER_LOADING } from '../../../../redux/loading/constants';

const Categories = () => {
  const categories = useSelector(getCategoriesSelector);
  const loading = useSelector(state => isTaskLoading(state, HELP_CENTER_LOADING));

  if (loading && !categories.length) return <PlHelpCenterCards />;

  return (
    <Cards>
      {categories.map(el => (<CardItem key={el.id} category={el} />))}
    </Cards>
  );
};

export default Categories;
