import { createAction } from 'redux-actions';

export const SET_CATEGORIES = 'HELP_CENTER::SET_CATEGORIES';
export const SET_TOPICS = 'HELP_CENTER::SET_TOPICS';
export const UPDATE_TOPIC = 'HELP_CENTER::UPDATE_TOPIC';
export const VOTE_TOPIC = 'HELP_CENTER::VOTE_TOPIC';

export const setCategories = createAction(SET_CATEGORIES);
export const setTopics = createAction(SET_TOPICS);
export const updateTopic = createAction(UPDATE_TOPIC);
export const voteTopic = createAction(VOTE_TOPIC);
