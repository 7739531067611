import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import supportChat from 'support-usl-chat';

import { isUserLoadedSelector } from '../../../../redux/user/selectors';
import SidebarSection from './SidebarSection';
import Text from '../../../../components/Text';
import { $l } from '../../../../locales';
import Button from '../../../../components/Button';
import Separator from '../Separator';

const callChat = () => {
  supportChat.startLoad();
};

const ChatButton = () => {
  const isUserLoaded = useSelector(isUserLoadedSelector);

  return (
    <Fragment>
      <SidebarSection>
        <Text element="h3">{$l('STILL_HAVE_QUESTIONS')}</Text>
      </SidebarSection>
      <SidebarSection>
        <Button
          icon="sp--colored--16--message"
          label={$l('LIVE_SUPPORT_CHAT')}
          disabled={!isUserLoaded}
          onClick={callChat}
        >
          {$l('LIVE_SUPPORT_CHAT')}
        </Button>
      </SidebarSection>
      <Separator />
    </Fragment>
  );
};

export default ChatButton;
