import { acceptedFileTypes } from '../redux/tickets/constants';

export const acceptedFileTypesForInput = acceptedFileTypes.join(', ');
export const checkSimilarName = name => new RegExp(name.replace(/\.([^.]+)$/g, ' ([0-9]+).$1').replace(/\(|\)/g, '\\$&'), 'g');
export const newFileName = (name, nameInAttachments) => (nameInAttachments ? name.replace(/\.([^.]+)$/g, ` (${nameInAttachments}).$1`) : name);
export const getFileAttachment = name => {
  if (!name) return 'file';

  return name.trim().split('.').reverse()[0] || 'file';
};
