import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../../../../../components/Icon';
import { $l } from '../../../../../locales';

const Callback = ({ callback }) => (
  <div className="sp-chat-form">
    <div className="sp-chat-form__body">
      <div className="sp-chat-form__section">
        <div className="sp-chat-form__alert-center">
          <div className="sp-chat-form__alert">
            <div className="sp-chat-form__alert-icon">
              <Icon id="colored--24--success" size="24" />
            </div>
            <div className="sp-chat-form__alert-title sp-chat-form__alert-title--big">
              {$l('THANK_YOU')}
            </div>
            <div className="sp-chat-form__alert-description">
              {callback.message}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

Callback.propTypes = {
  callback: PropTypes.shape({
    message: PropTypes.string.isRequired,
  }).isRequired,
};

export default Callback;
