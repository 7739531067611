import { createSelector } from 'reselect';

import { DEFAULT_BRANCH } from '../../constants/experiment';

export const getExperiments = state => state.experiments;

export const experimentBranchSelector = createSelector(
  [getExperiments, (_, name) => name],
  (experiments, name) => experiments[name] || DEFAULT_BRANCH,
);
