import React, { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { modalsSelector } from '../../../redux/modals/selectors';

const cloneChild = ({ activeModals, options }) => child => (
  activeModals[child.props.id]
  && cloneElement(child, options[child.props.id])
);

const ModalsDispatcher = ({ children }) => {
  const modals = useSelector(modalsSelector);

  return (
    <div className="sp-modal-list">
      {Children.map(children, cloneChild(modals))}
    </div>
  );
};

ModalsDispatcher.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

export default ModalsDispatcher;