import React, { useEffect, useCallback, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import supportChat from 'support-usl-chat';

import List from '../List';
import MainSection from '../../../../components/MainSection';
import EmptyPage from '../EmptyPage';

import { userIdSelector } from '../../../../redux/user/selectors';
import { isBottomSelector } from '../../../../redux/helper/selectors';
import { chatsHistorySelector } from '../../../../redux/chatsHistory/selectors';
import { loadChatsHistory } from '../../../../redux/chatsHistory/thunks';
import { isTaskLoading } from '../../../../redux/loading/selectors';
import { CHATS_HISTORY_LOADING } from '../../../../redux/loading/constants';
import useMounted from '../../../../hooks/useMounted';
import { $l } from '../../../../locales';

const ChatsHistoryContainer = () => {
  const mounted = useMounted();
  const dispatch = useDispatch();
  const chatsHistory = useSelector(chatsHistorySelector);
  const userId = useSelector(userIdSelector);
  const isBottom = useSelector(isBottomSelector);
  const loading = useSelector(state => isTaskLoading(state, CHATS_HISTORY_LOADING));

  useEffect(() => {
    if (userId) {
      dispatch(loadChatsHistory());
    }
  }, [userId]);

  useEffect(() => {
    if (isBottom) {
      dispatch(loadChatsHistory());
    }
  }, [isBottom]);

  const buttonHandler = useCallback(() => {
    supportChat.startLoad();
  }, []);

  return (
    <Fragment>
      <MainSection className="sp-offset--b--sm">
        <List items={chatsHistory} loading={loading} />
      </MainSection>
      {!loading && !chatsHistory.length && mounted && (
        <MainSection className="sp-offset--b--sm">
          <EmptyPage
            icon="sp--colored--48--document"
            title={$l('NO_CHATS')}
            description={$l('NO_CHATS_DESCRIPTION')}
            buttonText={$l('CONTACT_SUPPORT')}
            buttonHandler={buttonHandler}
            containerClasses="sp-container__inner--offset-t--md sp-container__inner--sm"
          />
        </MainSection>
      )}
    </Fragment>
  );
};

export default ChatsHistoryContainer;
