import { handleActions } from 'redux-actions';

import * as AC from './actions';

const initialState = {
  categories: [],
  topics: {},
  voted: JSON.parse(localStorage.getItem('support-voted-topics')) || [],
};

export default handleActions(
  {
    [AC.SET_CATEGORIES]: (store, { payload }) => ({
      ...store, categories: payload,
    }),
    [AC.SET_TOPICS]: (store, { payload }) => ({
      ...store, topics: payload,
    }),
    [AC.UPDATE_TOPIC]: (store, { payload }) => ({
      ...store,
      topics: {
        ...store.topics,
        [payload.categorySlug]: store.topics[payload.categorySlug]
          .map(el => (el.id !== payload.id ? el : { ...el, ...payload })),
      },
    }),
    [AC.VOTE_TOPIC]: (store, { payload }) => ({
      ...store, voted: [...store.voted, payload],
    }),
  },
  initialState,
);
