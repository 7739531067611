import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Select from '../../../../components/Select';
import { sortTicketOptions } from '../../../../redux/tickets/constants';
import { loadTickets } from '../../../../redux/tickets/thunks';
import { loadChatsHistory } from '../../../../redux/chatsHistory/thunks';
import { changeTicketsSort, resetTickets } from '../../../../redux/tickets/actions';
import { resetChatsHistory } from '../../../../redux/chatsHistory/actions';
import { activeTabSelector, sortSelector } from '../../../../redux/tickets/selectors';
import { $l } from '../../../../locales';

const Action = () => {
  const dispatch = useDispatch();
  const activeTab = useSelector(activeTabSelector);
  const sort = useSelector(sortSelector);
  const options = sortTicketOptions();

  const changeSortHandler = useCallback((value) => {
    dispatch(changeTicketsSort(value));
    dispatch(resetTickets());
    dispatch(resetChatsHistory());

    if (activeTab === 'tickets') {
      dispatch(loadTickets());
    } else {
      dispatch(loadChatsHistory());
    }
  }, [activeTab]);

  return (
    <div className="sp-tabs__action">
      <Select
        title={$l('SORT_FIRST')}
        value={sort}
        options={options}
        onClick={changeSortHandler}
        transparent
      />
    </div>
  );
};

export default Action;
