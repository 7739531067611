import throttle from 'lodash.throttle';

const key = 'us-legal-support-state';

const getInitialState = () => {
  try {
    const serializedState = localStorage.getItem(key);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (e) {
    return undefined;
  }
};

const persistSelector = ({
  helpCenter, user, categoriesList,
}) => ({
  helpCenter,
  user: { ...user, isLoaded: false },
  categoriesList,
});

const saveState = (store, selector) => {
  try {
    const serializedState = JSON.stringify(selector(store.getState()));
    localStorage.setItem(key, serializedState);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('State writing error', e);
  }
};

let unsubscribe = null;

const listen = store => {
  const updateState = () => { saveState(store, persistSelector); };

  unsubscribe = store.subscribe(throttle(updateState, 1000));
};

const stopListening = () => typeof unsubscribe === 'function' && unsubscribe();

const clear = () => {
  localStorage.removeItem(key);
};

export default {
  getInitialState,
  listen,
  stopListening,
  clear,
};
