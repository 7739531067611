import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';

import Category from '../HelpCenterCategory/Category';
import MainSection from '../../../../components/MainSection';
import Separator from '../Separator';
import { getCategoriesSelector } from '../../../../redux/helpCenter/selectors';

const Categories = () => {
  const categories = useSelector(getCategoriesSelector);

  return (
    <Fragment>
      {categories.map(el => (
        <Fragment key={el.id}>
          <Category category={el} />
          {!el.isLast && (
            <MainSection className="sp-offset--b--sm">
              <Separator />
            </MainSection>
          )}
        </Fragment>
      ))}
    </Fragment>
  );
};

export default Categories;
