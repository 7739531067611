import React, { Fragment } from 'react';

const PlHelpCenterCategories = () => (
  <Fragment>
    <div className="sp-main__section sp-offset--b--cm">
      <div className="sp-breadcrumbs">
        <div className="sp-breadcrumbs__list">
          <div className="sp-breadcrumbs__item">
            <div className="sp-placeholder sp-placeholder--cm" />
            <div className="sp-placeholder-icon sp-placeholder-icon--14" />
          </div>
          <div className="sp-breadcrumbs__item">
            <div className="sp-placeholder sp-placeholder--cm" />
          </div>
        </div>
      </div>
    </div>
    <div className="sp-main__section sp-main__section--offset-t--cm sp-main__section--offset-b--md">
      <div className="sp-placeholder-input">
        <div className="sp-placeholder-icon sp-placeholder-icon--14" />
        <div className="sp-text">
          <div className="sp-placeholder" />
        </div>
      </div>
    </div>
    <div className="sp-main__section sp-offset--b--sm">
      <div className="sp-separator">
        <div className="sp-separator__item" />
      </div>
    </div>
    <div className="sp-main__section sp-offset--t--cm">
      <h2 className="sp-text sp-text--h2">
        <div className="sp-placeholder" />
      </h2>
    </div>
    <div className="sp-main__section sp-offset--b--sm">
      <div className="sp-text">
        <div className="sp-placeholder" />
      </div>
    </div>
    <div className="sp-main__section sp-offset--b--sm sp-offset--t--md">
      <h3 className="sp-text sp-text--h3">
        <div className="sp-placeholder" />
      </h3>
    </div>
    <div className="sp-main__section sp-offset--b--sm">
      <div className="sp-text">
        <div className="sp-placeholder" />
      </div>
    </div>
    <div className="sp-main__section sp-offset--b--sm">
      <div className="sp-simple-mesh" data-flex="1">
        <div className="sp-text sp-text--offset--cm">
          <div className="sp-placeholder" />
          <div className="sp-placeholder sp-placeholder--50" />
        </div>
        <div className="sp-text sp-text--offset--cm">
          <div className="sp-placeholder" />
          <div className="sp-placeholder sp-placeholder--50" />
        </div>
      </div>
    </div>
    <div className="sp-main__section sp-offset--b--sm">
      <div className="sp-simple-mesh" data-flex="1">
        <div className="sp-text sp-text--offset--cm">
          <div className="sp-placeholder" />
          <div className="sp-placeholder sp-placeholder--50" />
        </div>
        <div className="sp-text sp-text--offset--cm">
          <div className="sp-placeholder" />
          <div className="sp-placeholder sp-placeholder--50" />
        </div>
      </div>
    </div>
    <div className="sp-main__section sp-offset--b--sm">
      <div className="sp-simple-mesh" data-flex="1">
        <div className="sp-text sp-text--offset--cm">
          <div className="sp-placeholder" />
          <div className="sp-placeholder sp-placeholder--50" />
        </div>
        <div className="sp-text sp-text--offset--cm">
          <div className="sp-placeholder" />
          <div className="sp-placeholder sp-placeholder--50" />
        </div>
      </div>
    </div>
    <div className="sp-main__section sp-offset--b--sm">
      <div className="sp-simple-mesh" data-flex="1">
        <div className="sp-text sp-text--offset--cm">
          <div className="sp-placeholder" />
          <div className="sp-placeholder sp-placeholder--50" />
        </div>
        <div className="sp-text sp-text--offset--cm">
          <div className="sp-placeholder" />
          <div className="sp-placeholder sp-placeholder--50" />
        </div>
      </div>
    </div>
    <div className="sp-main__section sp-offset--b--sm">
      <div className="sp-simple-mesh" data-flex="1">
        <div className="sp-text sp-text--offset--cm">
          <div className="sp-placeholder" />
          <div className="sp-placeholder sp-placeholder--50" />
        </div>
        <div className="sp-text sp-text--offset--cm">
          <div className="sp-placeholder" />
          <div className="sp-placeholder sp-placeholder--50" />
        </div>
      </div>
    </div>
    <div className="sp-main__section sp-offset--b--sm">
      <div className="sp-simple-mesh" data-flex="1">
        <div className="sp-text sp-text--offset--cm">
          <div className="sp-placeholder" />
          <div className="sp-placeholder sp-placeholder--50" />
        </div>
        <div className="sp-text sp-text--offset--cm">
          <div className="sp-placeholder" />
          <div className="sp-placeholder sp-placeholder--50" />
        </div>
      </div>
    </div>
    <div className="sp-main__section sp-offset--b--sm">
      <div className="sp-separator">
        <div className="sp-separator__item" />
      </div>
    </div>
  </Fragment>
);

export default PlHelpCenterCategories;
