export const dateValidation = val => {
  const re = /^(\d+)-(\d+)-(\d+)[T\s]?(\d+):(\d+):(\d+)(?:\.\d+)?Z?$/i;

  return !!re.exec(val);
};

export const urlsValidation = (url = '') => {
  const reg = /^(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/;

  return reg.test(url);
};

export const emailValidation = (text = '') => {
  const reg = /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z](?:[a-z]*[a-z])?$/;

  return reg.test(text);
};
