import React, { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from './Icon';
import useOutsideClick from '../hooks/useOutsideClick';
import { $l } from '../locales';

const Select = ({
  value,
  options,
  onClick,
  transparent,
  placeholder,
  title,
}) => {
  const [open, toggle] = useState(false);
  const ref = useRef(null);

  const toggleHandler = () => toggle(prevState => !prevState);

  const outsideClickHandler = useCallback(() => {
    if (open) toggle(false);
  }, [open]);

  useOutsideClick(ref, outsideClickHandler);

  return (
    <div
      className={classNames('sp-select', { 'is-open': open, 'sp-select--transparent': transparent })}
      aria-label="Select"
      ref={ref}
      onClick={toggleHandler}
    >
      {title && <div className="sp-select__title">{title}</div>}
      <div className="sp-select__body">
        <div className="sp-select__control">
          <div className="sp-select__value" aria-label="Value of select item" aria-expanded="false">
            <div className="sp-select__value-text">{value.name || placeholder || $l('SELECT_CATEGORY')}</div>
          </div>
          <div className="sp-select__icon">
            <Icon id="sp--16--arrow-down" />
          </div>
        </div>
        <div className="sp-select__options">
          <div className="sp-select__options-body">
            {options.map(el => (
              <div
                key={el.id}
                className={classNames('sp-select__options-item', { 'is-selected': el.id === value.id })}
                onClick={() => onClick(el)}
              >
                {el.name}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

Select.propTypes = {
  value: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]).isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  onClick: PropTypes.func.isRequired,
  transparent: PropTypes.bool,
  placeholder: PropTypes.string,
  title: PropTypes.string,
};

Select.defaultProps = {
  transparent: false,
  placeholder: '',
  title: '',
};

export default Select;
