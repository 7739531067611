import React from 'react';
import PropTypes from 'prop-types';

const NavItem = ({ children }) => (
  <li className="sp-nav__item">
    {children}
  </li>
);

NavItem.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

export default NavItem;
