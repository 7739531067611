import { MIN_SEARCH_STRING_LENGTH } from './constants';

export const isCountResultInSearch = counts => (
  Array.isArray(counts) && !!counts.filter(el => !!el.total).length
);

export const isValidSearchString = value => (
  value && value.trim() && value.trim().length >= MIN_SEARCH_STRING_LENGTH
);

export const getActiveTab = (data, selectedType) => {
  let type = null;
  let i = data.length;

  while (i--) {
    const el = data[i];

    if (el.total > 0) {
      if (el.type === selectedType) {
        return selectedType;
      }

      type = el.type;
    }
  }

  return type;
};
