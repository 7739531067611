import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import Button from '../../../../components/Button';
import { isClickedUsefulTopicSelector } from '../../../../redux/helpCenter/selectors';
import { sendUsefulTopic } from '../../../../redux/helpCenter/thunks';
import { $l } from '../../../../locales';

const Helpful = ({ topic }) => {
  const dispatch = useDispatch();
  const isClicked = useSelector(state => isClickedUsefulTopicSelector(state, topic.slug));

  const sendNotHelpful = useCallback(() => {
    dispatch(sendUsefulTopic({ topic, flag: 0 }));
  }, [topic]);

  const sendHelpful = useCallback(() => {
    dispatch(sendUsefulTopic({ topic, flag: 1 }));
  }, [topic]);

  return (
    <article className="sp-article sp-article--theme--bordered">
      <div className="sp-article__section">
        <div className="sp-article__item">
          <p className="sp-text sp-text--h5">
            {$l('WAS_THIS_ARTICLE_HELPFUL')}
          </p>
          {topic.useful > 0 && (
            <p className="sp-text sp-text--color--b-g-2">
              {topic.useful}
              {$l('PEOPLE_FOUND_THIS_HELPFUL')}
            </p>
          )}
        </div>
        {!isClicked && (
          <div className="sp-article__item">
            <div className="sp-article__control">
              <Button
                mode="secondary"
                size="sm"
                className="sp-control--w--124 sp-control--offset--r"
                onClick={sendNotHelpful}
              >
                {$l('NOR_REALLY')}
              </Button>
              <Button
                size="sm"
                className="sp-control--w--124"
                onClick={sendHelpful}
              >
                {$l('YES_THANKS')}
              </Button>
            </div>
          </div>
        )}
      </div>
    </article>
  );
};

Helpful.propTypes = {
  topic: PropTypes.shape({
    useful: PropTypes.number.isRequired,
    slug: PropTypes.string.isRequired,
  }).isRequired,
};

export default Helpful;
