import React, { Fragment } from 'react';

import Text from '../../../components/Text';
import Separator from '../components/Separator';
import SearchInput from '../components/Search/SearchInput';
import MainSection from '../../../components/MainSection';
import Categories from '../components/HelpCenter/Categories';
import RecentTopics from '../components/HelpCenter/RecentTopics';
import { $l } from '../../../locales';

const HelpCenter = () => (
  <Fragment>
    <MainSection className="sp-main__section--offset-b--cm">
      <Text element="h1">{$l('HELP_CENTER_TITLE')}</Text>
    </MainSection>
    <MainSection className="sp-main__section--offset-b--cm">
      <Text>{$l('HELP_CENTER_DESCRIPTION')}</Text>
    </MainSection>
    <MainSection className="sp-main__section--offset-t--cm sp-main__section--offset-b--md">
      <SearchInput />
    </MainSection>
    <Separator />
    <MainSection className="sp-main__section--offset-t--md sp-main__section--offset-b--md">
      <Text element="h2">{$l('US_LEGAL_FORM_OVERVIEW')}</Text>
    </MainSection>
    <MainSection className="sp-main__section--offset-b--cm">
      <Categories />
    </MainSection>
    <MainSection className=" sp-main__section--offset-t--cm">
      <Text element="h3">{$l('RECENT_TOPICS')}</Text>
    </MainSection>
    <RecentTopics />
  </Fragment>
);

export default HelpCenter;
