import React from 'react';
import {
  Switch,
  Route,
  Redirect,
  useLocation,
} from 'react-router-dom';

import routes from '../../routes';

import HelpCenter from './containers/HelpCenter';
import HelpCenterCategories from './containers/HelpCenterCategories';
import HelpCenterCategory from './containers/HelpCenterCategory';
import HelpCenterTopic from './containers/HelpCenterTopic';
import Search from './containers/Search';
import Tickets from './containers/Tickets';
import Ticket from './containers/Ticket';
import ChatHistory from './containers/ChatHistory';

const PageByRoute = () => {
  const { pathname } = useLocation();
  const redirect = !pathname.endsWith('/') && !pathname.startsWith('/robots.txt');

  return (
    <Switch>
      {redirect && <Redirect from={pathname} to={`${pathname}/`} />}
      <Route exact strict path={`${routes.$root}`} component={HelpCenter} />
      <Route exact strict path={`${routes.helpCenter}/`} component={HelpCenterCategories} />
      <Route exact strict path={`${routes.helpCenterCategory}/`} component={HelpCenterCategory} />
      <Route exact strict path={`${routes.helpCenterTopic}/`} component={HelpCenterTopic} />
      <Route exact strict path={`${routes.search}/`} component={Search} />
      <Route exact strict path={`${routes.tickets}/`} component={Tickets} />
      <Route exact strict path={`${routes.ticket}/`} component={Ticket} />
      <Route exact strict path={`${routes.chat}/`} component={ChatHistory} />
    </Switch>
  );
};

export default PageByRoute;
