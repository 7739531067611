import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Icon from '../../../../components/Icon';
import routes from '../../../../routes';
import { $l } from '../../../../locales';

const Breadcrumbs = ({ items }) => (
  <nav className="sp-breadcrumbs" role="navigation">
    <ul className="sp-breadcrumbs__list">
      <li className="sp-breadcrumbs__item">
        <Link to={routes.$root} className="sp-breadcrumbs__link">
          {$l('SUPPORT')}
        </Link>
        <div className="sp-breadcrumbs__icon">
          <Icon id="sp--16--arrow-right" />
        </div>
      </li>
      {items.map(el => (
        el.link ? (
          <li key={el.name} className="sp-breadcrumbs__item">
            <Link to={el.link} className="sp-breadcrumbs__link">
              {el.name}
            </Link>
            <div className="sp-breadcrumbs__icon">
              <Icon id="sp--16--arrow-right" />
            </div>
          </li>
        ) : (
          <li key={el.name} className="sp-breadcrumbs__item">
            <div className="sp-breadcrumbs__link sp-breadcrumbs__active">
              {el.name}
            </div>
          </li>
        )
      ))}
    </ul>
  </nav>
);

Breadcrumbs.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    link: PropTypes.string,
    name: PropTypes.string.isRequired,
  })).isRequired,
};

export default Breadcrumbs;
