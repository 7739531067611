import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';

import EmptyPage from '../apps/desktop/components/EmptyPage';
import { $l } from '../locales';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  // eslint-disable-next-line class-methods-use-this,no-unused-vars
  componentDidCatch(error, errorInfo) {
    // eslint-disable-next-line no-console
    console.log('[componentDidCatch]', error);
    Sentry.withScope((scope => {
      scope.setExtras(errorInfo);
      Sentry.captureException(error);
    }));
  }

  // eslint-disable-next-line class-methods-use-this
  redirectToHome() {
    window.location.href = '/';
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        <EmptyPage
          icon="sp--colored--48--document"
          title={`${$l('OOPS')} ${$l('SOMETHING_WENT_WRONG')}`}
          buttonText={$l('HOME')}
          buttonHandler={this.redirectToHome}
        />
      );
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

export default ErrorBoundary;
