import linkifyHtml from 'linkifyjs/html';
import { sanitize } from 'sanitizer';

import nl2br from './nl2br';

export default (text, attributeToRemove) => {
  if (attributeToRemove && text) {
    const re = new RegExp(`${attributeToRemove}="[a-zA-Z0-9:;.s()-,]*"`, 'gi');

    return {
      __html: text.replace(re, ''),
    };
  }

  return {
    __html: linkifyHtml(nl2br(sanitize(text, (uri) => uri)), {
      target: '_blank',
      ignoreTags: ['code'],
    }),
  };
};
