import moment from 'moment-timezone';
import { dateValidation } from './validations';

export const getDateToFormat = (time) => {
  if (!dateValidation(time && time.toString())) {
    return time;
  }

  const localTimezone = moment.tz.guess();
  const newYorkTimeZone = 'America/New_York';

  const date = moment.tz(time, newYorkTimeZone);
  const utc = date.valueOf();
  const now = date.tz(localTimezone).valueOf();
  const diff = now - utc;

  return new Date(now + diff);
};

export default time => {
  const dateToFormat = getDateToFormat(time);

  return `${dateToFormat.toLocaleString('en-US', {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
  })} at ${dateToFormat.toLocaleString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
  }).toLocaleLowerCase()}`;
};

export const onlyDate = time => {
  const dateToFormat = getDateToFormat(time);

  return dateToFormat.toLocaleString('en-US', {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
  });
};

export const onlyTime = time => {
  const dateToFormat = getDateToFormat(time);

  return dateToFormat.toLocaleString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
  }).toLocaleUpperCase();
};
