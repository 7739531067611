import React from 'react';
import { render } from 'react-dom';
import * as Sentry from '@sentry/browser';

import App from '../src/apps/desktop';
import config from '../src/config';

if (config.SENTRY_DSN) {
  Sentry.init({
    dsn: config.SENTRY_DSN,
    environment: 'desktop',
  });
}

render(<App />, document.getElementById('main-root'));
