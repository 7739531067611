import React, { useMemo } from 'react';
import SupportChatButton from 'support-usl-chat/src/SupportChatButton';
import { useSelector } from 'react-redux';
import { isUserLoadedSelector, userIdSelector, userEmailSelector } from '../../../redux/user/selectors';

const HelpCenter = () => {
  const userId = useSelector(userIdSelector);
  const email = useSelector(userEmailSelector);
  const isUserLoaded = useSelector(isUserLoadedSelector);
  const user = useMemo(() => ({
    id: userId,
    email,
  }), [email, userId]);

  if (!isUserLoaded) return null;

  return <SupportChatButton user={user} />;
};

export default HelpCenter;
