import React from 'react';
import PropTypes from 'prop-types';

const Nav = ({ children }) => (
  <nav className="sp-nav" role="navigation">
    {children}
  </nav>
);

Nav.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

export default Nav;
