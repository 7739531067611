import api from '../../api';
import { loadingThunk } from '../loading/thunks';
import { CHATS_HISTORY_LOADING, CHAT_HISTORY_LOADING } from '../loading/constants';
import { setChatsHistory, setChatHistory } from './actions';
import { isTaskLoading } from '../loading/selectors';
import propsToQuery from '../../utils/propsToQuery';
import { endLoading, startLoading } from '../loading/actions';

export const loadChatsHistory = () => async (dispatch, getState) => {
  const state = getState();
  const isLoading = isTaskLoading(state, CHATS_HISTORY_LOADING);
  const {
    chatsHistory: { ids, pagination, isLoaded },
    tickets: { sort },
  } = state;

  const isTicketsLoaded = (ids.length > 0 && ids.length >= pagination.total && pagination.total > 0)
    || (isLoaded && pagination.total === 0);
  if (isLoading || isTicketsLoaded) return;

  try {
    dispatch(startLoading(CHATS_HISTORY_LOADING));

    const { data, perPage, total } = await api.get(`chat${propsToQuery({
      page: Math.floor(ids.length / pagination.perPage) + 1,
      per_page: pagination.perPage,
      order_by_direction: sort.id,
    })}`);

    dispatch(setChatsHistory({
      chatsHistory: data,
      pagination: { perPage, total },
    }));
  } catch (err) {
    //
  } finally {
    dispatch(endLoading(CHATS_HISTORY_LOADING));
  }
};

export const loadChatHistoryAction = id => async dispatch => {
  try {
    const data = await api.get(`chat/${id}`);

    if (!data.errors) {
      dispatch(setChatHistory({ id, ...data }));
    }
  } catch (e) {
    //
  }
};

export const loadChatHistory = loadingThunk(CHAT_HISTORY_LOADING)(loadChatHistoryAction);
