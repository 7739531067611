import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Icon from '../../../../components/Icon';
import SupportImage from '../SupportImage';

import ChatDelimiter from './ChatDelimiter';
import Message from './Message/Message';
import Attachments from './Message/Attachments';
import Callback from './Message/Callback';

const ChatMessage = ({ message }) => (
  <Fragment>
    {message.delimiter && <ChatDelimiter text={message.delimiter} />}
    <div className="sp-chat-main__section">
      <div className={`sp-${message.statusType}-message`}>
        <div className={`sp-${message.statusType}-message__avatar`}>
          {message.img ? <SupportImage img={message.img} /> : <Icon id="sp--colored--32--avatar" size="32" />}
        </div>
        <div className={`sp-${message.statusType}-message__body`}>
          <div className={`sp-${message.statusType}-message__section`}>
            {message.message && <Message message={message.message} />}
            <Attachments attachments={message.attachments} statusType={message.statusType} />
            {message.type === 'callback' && <Callback callback={message.callback} />}
          </div>
          <div className="sp-chat__submessage">{message.createdAtFormatted}</div>
        </div>
      </div>
    </div>
  </Fragment>
);

ChatMessage.propTypes = {
  message: PropTypes.shape({
    delimiter: PropTypes.string.isRequired,
    statusType: PropTypes.string.isRequired,
    img: PropTypes.string,
    message: PropTypes.string.isRequired,
    attachments: PropTypes.arrayOf(PropTypes.object),
    type: PropTypes.string.isRequired,
    callback: PropTypes.object,
    createdAtFormatted: PropTypes.string.isRequired,
  }).isRequired,
};

export default ChatMessage;
