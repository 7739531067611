import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Category from '../components/HelpCenterCategory/Category';
import RelatedCategories from '../components/HelpCenterCategory/RelatedCategories';
import Breadcrumbs from '../components/Breadcrumbs';
import MainSection from '../../../components/MainSection';
import SearchInput from '../components/Search/SearchInput';
import Separator from '../components/Separator';
import PlHelpCenterCategories from '../components/Placeholders/PlHelpCenterCategories';
import { getCategoryBySlugSelector, getHelpCenterLoadingSelector } from '../../../redux/helpCenter/selectors';
import routes from '../../../routes';
import { $l } from '../../../locales';

const HelpCenterCategory = ({ match: { params: { category: slug } } }) => {
  const category = useSelector(state => getCategoryBySlugSelector(state, slug));
  const loading = useSelector(getHelpCenterLoadingSelector);

  if (loading || !category) return <PlHelpCenterCategories />;

  const breadcrumbs = [
    { name: $l('HELP_CENTER'), link: `${routes.helpCenter}/` },
    { name: category.name },
  ];

  return (
    <Fragment>
      <MainSection className="sp-offset--b--cm">
        <Breadcrumbs items={breadcrumbs} />
      </MainSection>
      <MainSection className="sp-main__section--offset-t--cm sp-main__section--offset-b--md">
        <SearchInput />
      </MainSection>
      <MainSection className="sp-offset--b--sm">
        <Separator />
      </MainSection>
      <Category category={category} onlyOne />
      <RelatedCategories categorySlug={slug} />
    </Fragment>
  );
};

HelpCenterCategory.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      category: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default HelpCenterCategory;
