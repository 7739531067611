import { handleActions } from 'redux-actions';

import * as AC from './actions';

export default handleActions({
  [AC.ADD_NOTIFICATION]: (state, { payload }) => ([...state, payload]),
  [AC.REMOVE_NOTIFICATION]: (state, { payload }) => (
    state.filter(notification => notification.id !== payload)
  ),
  [AC.CLEAR_NOTIFICATIONS]: () => [],
}, []);
