import { createSelector } from 'reselect';

import routes from '../../routes';
import toLocaleTime, { onlyTime } from '../../utils/toLocaleTime';
import fileSizeFormat from '../../utils/fileSizeFormat';
import {
  getRate,
  getCallbackMessage,
  getDelimiter,
  getImg,
} from './helpers';
import { $l } from '../../locales';

const root = state => state.chatsHistory;

export const getChatsHistoryById = state => root(state).chatsHistoryById;
export const getIds = state => root(state).ids;

export const chatsHistorySelector = createSelector(
  [getChatsHistoryById, getIds],
  (tickets, ids) => ids
    .map(id => {
      const chatHistory = tickets[id];
      return {
        ...chatHistory,
        title: `${chatHistory.id}: ${(chatHistory.firstMessage && chatHistory.firstMessage.message) || $l('NO_MESSAGE')}`,
        link: `${routes.tickets}/chat/${chatHistory.id}/`,
        description: toLocaleTime(chatHistory.closedAt),
        rate: getRate(chatHistory.rate),
        emptyRate: chatHistory.rate === 0,
      };
    }),
);

export const chatHistoryByIdSelector = createSelector(
  [getChatsHistoryById, (_, id) => id],
  (chats, id) => {
    const chat = chats[id];

    return chat && {
      ...chat,
      rate: getRate(chat.rate),
      messages: chat.messages && chat.messages.map((el, index) => ({
        ...el,
        delimiter: getDelimiter(el, chat.messages[index - 1]),
        statusType: el.authorableType.toLowerCase() !== 'staff' ? 'my' : 'supporter',
        img: el.img && getImg(el.img),
        attachments: el.attachments && el.attachments.map(item => ({
          ...item,
          extension: item.extension.toUpperCase(),
          size: fileSizeFormat(item.size),
        })),
        callback: el.callback && {
          ...el.callback,
          message: getCallbackMessage(el.callback),
        },
        createdAtFormatted: onlyTime(el.createdAt),
      })),
    };
  },
);
