import Locales from '@sv-front-platform/locales';

import translations from '../locales/translations.json';

const lastVersion = 94;

const config = {
  lang: 'en',
  cdnUrl: 'https://cdn.usrsprt.com/locales/sv-locales/{{lastVersion}}/{{lang}}/uslegal-support.json',
  variables: {
    lastVersion,
    lang: 'en',
  },
  interpolation: {},
  showConstants: false,
  defaultTransactions: translations,
};

let localization = key => translations[key];

const $l = text => localization(text);

const initLocales = async (callback) => {
  try {
    await Locales.init(config);
    localization = Locales.$g();
  } finally {
    callback();
  }
};

export { initLocales, $l, lastVersion };
