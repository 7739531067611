import { $l } from '../../locales';

export const MIN_SEARCH_STRING_LENGTH = 3;

export const PAGINATION_SIZE = 5;

export const types = () => [
  { slug: 'topic', title: $l('HELP_CENTER') },
  { slug: 'ticket', title: $l('TICKETS') },
];
