import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import Breadcrumbs from '../components/Breadcrumbs';
import MainSection from '../../../components/MainSection';
import SearchInput from '../components/Search/SearchInput';
import SearchResult from '../components/Search/SearchResult';
import Text from '../../../components/Text';

import {
  searchValueSelector,
  selectedTypeSelector,
  searchCountValueSelector,
} from '../../../redux/search/selectors';
import { isBottomSelector } from '../../../redux/helper/selectors';
import { setSearchValue, resetSearch } from '../../../redux/search/actions';
import {
  searchCount,
  searchByType,
  cancelCurrentRequests,
} from '../../../redux/search/thunks';
import { isValidSearchString } from '../../../redux/search/helpers';
import { $l } from '../../../locales';
import routes from '../../../routes';
import queryToParams from '../../../utils/queryToParams';

const Search = ({ location }) => {
  const dispatch = useDispatch();
  const searchValue = useSelector(searchValueSelector);
  const selectedType = useSelector(selectedTypeSelector);
  const searchCountValue = useSelector(searchCountValueSelector);
  const isBottom = useSelector(isBottomSelector);

  const breadcrumbs = [
    { name: $l('HELP_CENTER'), link: `${routes.helpCenter}/` },
    { name: $l('SEARCH_RESULT') },
  ];

  const searchHandler = q => {
    if (searchCountValue !== q) {
      dispatch(searchCount({ q, selectedType }));
    }
  };

  useEffect(() => () => {
    dispatch(resetSearch());
    cancelCurrentRequests();
  }, []);

  useEffect(() => {
    const { search } = location;

    if (search) {
      const params = queryToParams(search);
      const val = params.q;

      if (isValidSearchString(val)) {
        if (searchValue !== val) {
          dispatch(setSearchValue(val));
        }
        searchHandler(val);
      }
    }
  }, [location]);

  useEffect(() => {
    if (isBottom && isValidSearchString(searchValue)) {
      dispatch(searchByType());
    }
  }, [isBottom]);

  return (
    <Fragment>
      <MainSection className="sp-offset--b--cm">
        <Breadcrumbs items={breadcrumbs} />
      </MainSection>
      <MainSection className="sp-offset--t--cm sp-offset--b--cm">
        <Text element="h2">
          {$l('SEARCH_RESULT')}
        </Text>
      </MainSection>
      <MainSection className="sp-offset--b--md">
        <SearchInput />
      </MainSection>
      <SearchResult />
    </Fragment>
  );
};

Search.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
};

export default Search;
