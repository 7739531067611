import { createAction } from 'redux-actions';

export const SET_SEARCH_VALUE = 'SEARCH::SET_VALUE';
export const SET_COUNT_VALUE = 'SEARCH::SET_COUNT_VALUE';
export const SET_SEARCH_COUNTS = 'SEARCH::SET_COUNTS';
export const SET_SEARCH_RESULT = 'SEARCH::SET_RESULT';
export const UPDATE_SEARCH_RESULT = 'SEARCH::UPDATE_RESULT';
export const SET_SEARCH_TYPE = 'SEARCH::SET_TYPE';
export const RESET_SEARCH = 'SEARCH::RESET';

export const setSearchValue = createAction(SET_SEARCH_VALUE);
export const setCountValue = createAction(SET_COUNT_VALUE);
export const setSearchCounts = createAction(SET_SEARCH_COUNTS);
export const setSearchResult = createAction(SET_SEARCH_RESULT);
export const updateSearchResult = createAction(UPDATE_SEARCH_RESULT);
export const setSearchType = createAction(SET_SEARCH_TYPE);
export const resetSearch = createAction(RESET_SEARCH);
