import React, { Fragment } from 'react';

const PlItemList = () => (
  <Fragment>
    <li className="sp-help-list__item">
      <div className="sp-help-list__desc">
        <div className="sp-link sp-text--semibold">
          <div className="sp-text">
            <div className="sp-placeholder" />
            <div className="sp-placeholder sp-placeholder--50" />
          </div>
        </div>
      </div>
      <div className="sp-status-indicator">
        <div className="sp-placeholder sp-placeholder--cm" />
      </div>
    </li>
    <li className="sp-help-list__item">
      <div className="sp-help-list__desc">
        <div className="sp-link sp-text--semibold">
          <div className="sp-text">
            <div className="sp-placeholder" />
            <div className="sp-placeholder sp-placeholder--50" />
          </div>
        </div>
      </div>
      <div className="sp-status-indicator">
        <div className="sp-placeholder sp-placeholder--cm" />
      </div>
    </li>
    <li className="sp-help-list__item">
      <div className="sp-help-list__desc">
        <div className="sp-link sp-text--semibold">
          <div className="sp-text">
            <div className="sp-placeholder" />
            <div className="sp-placeholder sp-placeholder--50" />
          </div>
        </div>
      </div>
      <div className="sp-status-indicator">
        <div className="sp-placeholder sp-placeholder--cm" />
      </div>
    </li>
    <li className="sp-help-list__item">
      <div className="sp-help-list__desc">
        <div className="sp-link sp-text--semibold">
          <div className="sp-text">
            <div className="sp-placeholder" />
            <div className="sp-placeholder sp-placeholder--50" />
          </div>
        </div>
      </div>
      <div className="sp-status-indicator">
        <div className="sp-placeholder sp-placeholder--cm" />
      </div>
    </li>
  </Fragment>
);

export default PlItemList;
