import { getCookie, setCookie } from './cookies';
import { EXPERIMENT_USER_KEY } from '../constants/experiment';

const year = 31556952;

export const topLevelDomainRegexp = /([a-z0-9-]+.[a-z]{2,3})$/;

export const nestedLevelDomain = () => `.${window.location.hostname}`;

export const topLevelDomain = () => {
  const data = topLevelDomainRegexp.exec(window.location.hostname);
  return data && `.${data[0]}`;
};

export const generateGuestId = () => {
  const parts = [
    Math.floor(Date.now() / 1000).toString(),
    (`${Math.random().toString(36)}00000000000000000`).slice(2, 10 + 2),
  ];
  return parts.join('.');
};

export const getUserID = () => {
  const userFromCookie = getCookie(EXPERIMENT_USER_KEY);

  if (userFromCookie) return userFromCookie;

  const newGuestId = generateGuestId();

  setCookie(EXPERIMENT_USER_KEY, newGuestId, {
    expires: new Date(new Date().setUTCSeconds(year)),
    domain: topLevelDomain(),
  });

  setCookie(EXPERIMENT_USER_KEY, newGuestId, {
    expires: new Date(new Date().setUTCSeconds(year)),
    domain: nestedLevelDomain(),
  });

  return newGuestId;
};
