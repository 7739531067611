import { createSelector } from 'reselect';
import { idX } from '../helpers';
import routes from '../../routes';
import { isTaskLoading } from '../loading/selectors';
import { loadedUserIdSelector } from '../user/selectors';
import { TICKETS_LOADING } from '../loading/constants';

import toLocaleTime from '../../utils/toLocaleTime';
import { urlsValidation } from '../../utils/validations';
import {
  getTicketName,
  getTicketStatus,
  attachmentToView,
} from './helpers';
import { ORDERS, tabs } from './constants';
import { $l } from '../../locales';

const root = state => state.tickets;

export const getTicketsById = state => root(state).ticketsById;
export const getIds = state => root(state).ids;
export const getIsLoaded = state => root(state).isLoaded;
export const getAttachments = state => root(state).attachments;
export const hash = state => root(state).hash;
export const getSort = state => root(state).sort;
export const getUnread = state => root(state).unread;
export const getActiveTab = state => root(state).activeTab;
export const getTicketsLoading = state => isTaskLoading(state, TICKETS_LOADING);

export const activeTabSelector = createSelector(getActiveTab, idX);
export const sortSelector = createSelector(getSort, idX);

export const tabsSelector = createSelector(
  getActiveTab,
  activeTab => tabs()
    .map(el => ({ ...el, isActive: el.type === activeTab })),
);

export const attachmentsSelector = createSelector(
  getAttachments,
  attachments => attachments.map(attachmentToView),
);

export const ticketsSelector = createSelector(
  [getTicketsById, getIds, getSort],
  (tickets, ids, sort) => ids
    .map(id => {
      const ticket = tickets[id];
      return {
        ...ticket,
        title: getTicketName(ticket),
        link: `${routes.tickets}/${ticket.id}/`,
        description: toLocaleTime(ticket.createdAt),
        isActive: !!ticket.unread,
        lastMessageAuthorType: ticket.lastMessage && ticket.lastMessage.author.type,
        status: getTicketStatus(ticket),
      };
    })
    .sort((a, b) => {
      const firstField = a.createdAt;
      const secondField = b.createdAt;

      if (firstField === secondField) return 0;

      return (secondField < firstField ? 1 : -1) * (sort.id === ORDERS.asc ? 1 : -1);
    }),
);

export const getImg = img => (urlsValidation(img) ? img : null);

export const ticketByIdSelector = createSelector(
  [getTicketsById, (_, id) => +id],
  (tickets, id) => {
    const ticket = tickets[id];

    return ticket && {
      ...ticket,
      messages: ticket.messages
        && ticket.messages.map((el, index) => ({
          ...el,
          avatar: getImg(el.author.avatar),
          isLast: index === 0,
        })).reverse(),
      // eslint-disable-next-line max-len
      name: (ticket.initialMessage && ticket.initialMessage.message) || ticket.subject || $l('NO_SUBJECT').toLowerCase(),
      createdAtFormatted: toLocaleTime(ticket.createdAt),
      status: getTicketStatus(ticket),
      initialMessageAvatar: ticket.initialMessage
        && ticket.initialMessage.message
        && getImg(ticket.initialMessage.author.avatar),
    };
  },
);

export const ticketStatusByIdSelector = createSelector(
  [ticketByIdSelector, loadedUserIdSelector],
  (ticket, userId) => userId && ticket && getTicketStatus({
    ...ticket,
    messages: ticket.messages && [...ticket.messages].reverse(),
  }),
);

export const isLoadedTicketsSelector = createSelector(getIsLoaded, idX);

export const isAttachmentsLoadingSelector = createSelector(
  getAttachments,
  attachments => attachments.some(el => el.loading),
);

export const getTicketsHashSelector = createSelector(hash, idX);

export const getUnreadTicketsStateSelector = createSelector(getUnread, unread => unread > 0);

export const isMessageLoadingSelector = createSelector(
  ticketByIdSelector,
  ticket => ticket && !!ticket.messageLoading,
);

export const placeholderTicketMessagesLengthSelector = createSelector(
  ticketByIdSelector,
  ticket => {
    if (!ticket || !ticket.messages) return 2;
    if (ticket && ticket.messageLoading) return 1;
    return 0;
  },
);
