import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Sidebar = ({ position, className, children }) => (
  <aside
    className={classNames('sp-main__sidebar', {
      [`sp-main__sidebar--${position}`]: position,
      className,
    })}
  >
    <div className="sp-sidebar">
      {children}
    </div>
  </aside>
);

Sidebar.propTypes = {
  position: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

Sidebar.defaultProps = {
  className: '',
};

export default Sidebar;
