import React, { useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from '../../../../components/Icon';
import AttachmentContainer from './AttachmentContainer';
import { $l } from '../../../../locales';

const Attachment = ({ attachment, canDownload, removeHandler }) => {
  const remove = useCallback(() => removeHandler(attachment.id), [attachment.id]);

  return (
    <AttachmentContainer canDownload={canDownload} url={attachment.url} name={attachment.name}>
      <div className="sp-attachment__type">
        {attachment.extension}
        {canDownload && (
          <div className="sp-attachment-file-icon">
            <Icon id="colored--12--load-attachment" size="12" />
          </div>
        )}
      </div>
      <div className="sp-attachment__desc">
        <div className="sp-attachment__title">{attachment.name}</div>
        <div className="sp-attachment__subtitle">
          {attachment.loading ? (
            <div className="sp-attachment__subtitle-item">
              {$l('UPLOADING')}
            </div>
          ) : (
            <Fragment>
              {!removeHandler && (
                <div className="sp-attachment__subtitle-item">
                  {$l('CLICK_TO_DOWNLOAD')}
                </div>
              )}
              <span className="sp-attachment__subtitle-item">{attachment.fileSize}</span>
            </Fragment>
          )}
        </div>
      </div>
      {removeHandler && (
        <div className={classNames('sp-attachment__actions', { 'sp-attachment__actions--loader': attachment.loading })}>
          {attachment.loading ? (
            <Icon id="sp--16--load" />
          ) : (
            <button className="sp-control sp-control--transparent sp-control--small" onClick={remove}>
              <Icon id="sp--16--close-small" />
            </button>
          )}
        </div>
      )}
    </AttachmentContainer>
  );
};

Attachment.propTypes = {
  attachment: PropTypes.shape({
    id: PropTypes.number,
    extension: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    fileSize: PropTypes.string.isRequired,
    url: PropTypes.string,
    loading: PropTypes.bool,
  }).isRequired,
  canDownload: PropTypes.bool,
  removeHandler: PropTypes.func,
};

Attachment.defaultProps = {
  canDownload: false,
  removeHandler: null,
};

export default Attachment;
