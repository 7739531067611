import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../../../../components/Icon';

import {
  ERROR_TYPE,
  SUCCESS_TYPE,
  WARNING_TYPE,
  INFO_TYPE,
} from '../../../../redux/notifications/constants';

const iconsByType = {
  [ERROR_TYPE]: 'sp--white--32--success',
  [INFO_TYPE]: 'sp--white--32--success',
  [SUCCESS_TYPE]: 'sp--white--32--success',
  [WARNING_TYPE]: 'sp--white--32--success',
};

const NotificationIcon = ({ type }) => (
  <Icon id={iconsByType[type]} size="32" />
);

NotificationIcon.propTypes = {
  type: PropTypes.string.isRequired,
};

export default NotificationIcon;
