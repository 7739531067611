import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { isBottomSelector } from '../../../redux/helper/selectors';
import { setIsBottom } from '../../../redux/helper/actions';
import { infiniteLoaderIndent } from '../../../redux/helper/constants';

import Modals from '../containers/Modals';
import Notifications from '../containers/Notifications';

const MainContent = ({ children }) => {
  const dispatch = useDispatch();
  const isBottom = useSelector(isBottomSelector);

  const scrollHandler = e => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;

    if (scrollTop + clientHeight >= scrollHeight - infiniteLoaderIndent) {
      if (!isBottom) dispatch(setIsBottom(true));
    } else if (isBottom) {
      dispatch(setIsBottom(false));
    }
  };

  return (
    <div className="sp-main__body-wrapper" onScroll={scrollHandler}>
      <main className="sp-main__body sp-main__body--offset--md sp-main__body--center" role="main">
        <div className="sp-main__content sp-main__content--md sp-offset--b--md">
          {children}
        </div>
        <Modals />
        <Notifications />
      </main>
    </div>
  );
};

MainContent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

export default MainContent;
