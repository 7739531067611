import React, { Fragment, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import RelatedTopics from '../components/HelpCenterTopic/RelatedTopics';
import Helpful from '../components/HelpCenterTopic/Helpful';
import Breadcrumbs from '../components/Breadcrumbs';
import MainSection from '../../../components/MainSection';
import SearchInput from '../components/Search/SearchInput';
import Separator from '../components/Separator';
import Text from '../../../components/Text';
import PlHelpCenterCategories from '../components/Placeholders/PlHelpCenterCategories';
import {
  getCategoryBySlugSelector,
  getHelpCenterLoadingSelector,
  getTopicBySlugSelector,
  relatedTopicsSelector,
} from '../../../redux/helpCenter/selectors';
import { REFUND_ARTICLE_EXPERIMENT, REFUND_ARTICLE_VIEW_METRIC } from '../../../constants/experiment';
import { trackEvents } from '../../../redux/experiments/thunks';
import { openFullSizeImageModal } from '../../../redux/modals/thunks';
import routes from '../../../routes';
import { $l } from '../../../locales';
import messageHtml from '../../../utils/messageHtml';

const HelpCenterTopic = ({ match: { params: { category: categorySlug, topic: topicSlug } } }) => {
  const dispatch = useDispatch();
  const category = useSelector(state => getCategoryBySlugSelector(state, categorySlug));
  const topic = useSelector(state => getTopicBySlugSelector(state, topicSlug));
  const loading = useSelector(getHelpCenterLoadingSelector);
  const params = { topicSlug, categorySlug };
  const relatedTopics = useSelector(state => relatedTopicsSelector(state, params));
  const isExperimentTopic = useMemo(() => (
    topic
      && topic.experiments
      && Array.isArray(topic.experiments)
      && topic.experiments.includes(REFUND_ARTICLE_EXPERIMENT)
  ), [topic]);

  useEffect(() => {
    if (isExperimentTopic) {
      dispatch(trackEvents(REFUND_ARTICLE_VIEW_METRIC));
    }
  }, [isExperimentTopic]);

  if (loading || !topic || !category) return <PlHelpCenterCategories />;

  const breadcrumbs = [
    { name: $l('HELP_CENTER'), link: `${routes.helpCenter}/` },
    { name: category.name, link: `${routes.helpCenter}/${categorySlug}/` },
    { name: topic.name },
  ];

  const openImageInFullSize = (event) => {
    const { tagName, currentSrc } = event.target;

    if (tagName !== 'IMG') return;

    dispatch(openFullSizeImageModal({ imageLink: currentSrc }));
  };

  return (
    <Fragment>
      <MainSection className="sp-offset--b--cm">
        <Breadcrumbs items={breadcrumbs} />
      </MainSection>
      <MainSection className="sp-main__section--offset-t--cm sp-main__section--offset-b--md">
        <SearchInput />
      </MainSection>
      <MainSection className="sp-offset--b--sm">
        <Separator />
      </MainSection>
      <MainSection className="sp-offset--b--sm sp-offset--t--md">
        <Text element="h1" as="h3">{topic.name}</Text>
      </MainSection>
      <MainSection>
        <div
          onClick={openImageInFullSize}
          style={{ width: '100%' }}
          className="topic-description"
          dangerouslySetInnerHTML={messageHtml(topic.description, 'contenteditable')}
        />
      </MainSection>
      <MainSection className="sp-main__section--offset-t--cm">
        <Helpful topic={topic} />
      </MainSection>
      {relatedTopics && relatedTopics.length > 0 && (
        <Fragment>
          <MainSection className="sp-main__section--offset-t--cm">
            <Text element="h3">{$l('RELATED_ARTICLES')}</Text>
          </MainSection>
          <MainSection className="sp-main__section--offset-b--cm">
            <RelatedTopics relatedTopics={relatedTopics} />
          </MainSection>
        </Fragment>
      )}
    </Fragment>
  );
};

HelpCenterTopic.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      category: PropTypes.string.isRequired,
      topic: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default HelpCenterTopic;
