import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from './Icon';

const Button = ({
  type, mode, icon, iconSize, disabled, label, size,
  empty, className, children, ...customProps
}) => (
  <button
    type={type}
    className={classNames('sp-control', {
      [`sp-control--${mode}`]: mode,
      [`sp-control--${size}`]: size,
      'sp-control--empty': empty,
      [className]: className,
    })}
    disabled={disabled}
    aria-label={label}
    {...customProps}
  >
    <span className="sp-control__body">
      {icon && (
        <span className="sp-control__icon">
          <Icon id={icon} iconSize={iconSize} />
        </span>
      )}
      <span className="sp-control__text">{children}</span>
    </span>
  </button>
);

Button.propTypes = {
  type: PropTypes.string,
  mode: PropTypes.string,
  icon: PropTypes.string,
  iconSize: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  size: PropTypes.string,
  empty: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

Button.defaultProps = {
  type: 'button',
  mode: 'primary',
  icon: '',
  iconSize: '16',
  disabled: false,
  label: '',
  size: '',
  empty: false,
  className: '',
  children: '',
};

export default Button;
