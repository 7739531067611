import { handleActions } from 'redux-actions';

import * as AC from './actions';

const initialState = {
  activeModals: {},
  options: {},
};

const removeFieldInObject = (obj, field) => {
  const copy = { ...obj };

  delete copy[field];

  return copy;
};

export default handleActions(
  {
    [AC.OPEN_MODAL]: (store, { payload }) => ({
      ...store,
      activeModals: {
        ...store.activeModals,
        [payload.id]: true,
      },
      options: {
        ...store.options,
        [payload.id]: payload.params,
      },
    }),
    [AC.CLOSE_MODAL]: (store, { payload }) => ({
      ...store,
      activeModals: {
        ...store.activeModals,
        [payload]: false,
      },
      options: removeFieldInObject(store.options, payload),
    }),
  },
  initialState,
);
