import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';

import Notification from '../components/Notifications/Notification';
import { notificationsSelector } from '../../../redux/notifications/selectors';

const Notifications = () => {
  const notifications = useSelector(notificationsSelector);

  return (
    <Fragment>
      {notifications && notifications.map(el => (
        <Notification key={el.id} notification={el} />
      ))}
    </Fragment>
  );
};

export default Notifications;
