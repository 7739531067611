import api from '../../api';
import { startLoading, endLoading } from '../loading/actions';
import { loadingThunk } from '../loading/thunks';
import { isTaskLoading } from '../loading/selectors';
import propsToQuery from '../../utils/propsToQuery';
import { pipe } from '../helpers';

import {
  setTickets,
  setTicket,
  resetTickets,
  attachFile,
  updateAttachedFile,
  setUnreadTickets,
  updateUnreadTickets,
  detachFileByName,
} from './actions';
import {
  TICKETS_LOADING,
  TICKET_LOADING,
  CREATE_TICKET_LOADING,
  RESOLVE_TICKET_LOADING,
} from '../loading/constants';
import {
  transformDataToUnread,
  updateTicketMessages,
  mapTicketMessages,
} from './helpers';
import supportSocket, { getTicketsChannel } from '../../socket';
import toCamelCaseRecursive from '../../utils/camel-case';
import { openThankYouModal, closeCreateTicketModal } from '../modals/thunks';
import { notify } from '../notifications/actions';
import { $l } from '../../locales';
import { getUserID } from '../../utils/userGuestId';
import { emailValidation } from '../../utils/validations';

const handleError = (message) => notify.error({ title: $l('OOPS'), message: message || $l('SOMETHING_WENT_WRONG') });

export const loadTickets = () => async (dispatch, getState) => {
  const state = getState();
  const isLoading = isTaskLoading(state, TICKETS_LOADING);
  const { tickets: { ids, pagination, sort } } = state;

  const isTicketsLoaded = ids.length > 0 && ids.length >= pagination.total && pagination.total > 0;
  if (isLoading || isTicketsLoaded) return;

  try {
    dispatch(startLoading(TICKETS_LOADING));

    const {
      data, perPage, total, unread, hash,
    } = await api.get(`ticket${propsToQuery({
      page: Math.floor(ids.length / pagination.perPage) + 1,
      per_page: pagination.perPage,
      order_by_direction: sort.id,
    })}`);

    dispatch(setTickets({
      tickets: data,
      pagination: { perPage, total },
      hash,
      unread,
    }));
  } finally {
    dispatch(endLoading(TICKETS_LOADING));
  }
};

export const reloadTickets = () => dispatch => {
  dispatch(resetTickets());
  dispatch(loadTickets());
};

export const updateTicket = ({ id, ...params }) => dispatch => api.put(`ticket/${id}`, params)
  .then(toCamelCaseRecursive)
  .then(setTicket)
  .then(dispatch)
  .catch(() => {
    dispatch(handleError());
  });

export const resolveTicket = loadingThunk(RESOLVE_TICKET_LOADING)(updateTicket);

export const loadTicketAction = (id, query = {}) => dispatch => api.get(`ticket/${id}${propsToQuery(query)}`)
  .then(toCamelCaseRecursive)
  .then(mapTicketMessages)
  .then(setTicket)
  .then(dispatch);

export const loadTicket = loadingThunk(TICKET_LOADING)(loadTicketAction);

export const getTicket = id => async dispatch => {
  try {
    const { payload: { unread } } = await dispatch(loadTicket(id));

    if (unread) {
      await dispatch(updateTicket({ id, read: 1 }));
      dispatch(updateUnreadTickets(-unread));
    }
  } catch (e) {
    dispatch(handleError());
  }
};

export const getUnreadTickets = () => dispatch => api.get('unread')
  .then(toCamelCaseRecursive)
  .then(transformDataToUnread)
  .then(setUnreadTickets)
  .then(dispatch)
  .catch(() => {
    dispatch(handleError());
  });

export const createTicketAction = ({ reload, ...data }) => (dispatch, getState) => {
  const { user } = getState();
  const userEmail = emailValidation(user.email) ? user.email : null;
  const guestId = userEmail || user.id || getUserID();

  return api.post('ticket', {
    ...data,
    exp_guest_id: guestId,
  })
    .then(toCamelCaseRecursive)
    .then(mapTicketMessages)
    .then(res => {
      if (!res.ok && res.errors) {
        dispatch(handleError(res.errors));
        dispatch(closeCreateTicketModal());
        return;
      }

      if (!res.id) {
        throw new Error();
      }

      dispatch(closeCreateTicketModal());

      const { id: ticketId, email } = res;

      dispatch(setTicket(res));
      if (reload) {
        dispatch(reloadTickets());
      }
      dispatch(openThankYouModal({ ticketId, email }));
    })
    .catch(() => {
      dispatch(handleError());
    });
};

export const createTicket = loadingThunk(CREATE_TICKET_LOADING)(createTicketAction);

export const attachFileToMessage = props => dispatch => {
  dispatch(attachFile({ ...props, loading: true }));

  return api.post('ticket/attachment', props)
    .then(toCamelCaseRecursive)
    .then(updateAttachedFile)
    .then(dispatch)
    .catch(() => {
      dispatch(detachFileByName(props.name));
      dispatch(handleError());
    });
};

export const sendMessage = ({ id, ...params }) => (dispatch, getState) => {
  dispatch(setTicket({ id, messageLoading: true }));

  return api.post(`ticket/${id}/message`, params)
    .then(toCamelCaseRecursive)
    .then(message => {
      if (!message.id) {
        throw new Error();
      }

      const { tickets: { ticketsById } } = getState();
      const ticket = ticketsById[id];
      const res = pipe(
        updateTicketMessages({ id, message, ticket }),
        mapTicketMessages,
      );

      dispatch(setTicket(res));
      dispatch(notify.success({ title: $l('THANK_YOU'), message: $l('RESPONSE_SENT_SUCCESS') }));
    })
    .finally(() => {
      dispatch(setTicket({ id, messageLoading: false }));
    });
};

export const connectTicketsSocket = ({ userId, ticketsHash }) => async (dispatch, getState) => {
  const addTicketMessage = data => {
    const message = toCamelCaseRecursive(data.message);

    if (!message) return;

    const state = getState();
    const { tickets: { ticketsById } } = state;

    const id = message.ticketId;
    const ticketInTickets = ticketsById[id];

    if (window.location.pathname.includes(`tickets/${id}`)) {
      dispatch(updateTicket({ id, read: 1 }));
    } else if (message.author.type.toLowerCase() !== 'user') {
      // set ticket only if it exist in tickets
      if (ticketInTickets) {
        dispatch(setTicket({ id, unread: ticketInTickets.unread + 1 }));
      }
      dispatch(updateUnreadTickets(1));
    }

    // set message only if ticket exist in tickets
    if (ticketInTickets) {
      const res = pipe(
        updateTicketMessages({ id, message, ticket: ticketInTickets }),
        mapTicketMessages,
      );
      dispatch(setTicket(res));
    }
  };

  const setTicketHandler = (ticket) => pipe(
    toCamelCaseRecursive(ticket),
    mapTicketMessages,
    setTicket,
    dispatch,
  );

  const socket = await supportSocket();

  if (socket) {
    socket.private(getTicketsChannel(userId, ticketsHash))
      .listen('.ticket.message.created', addTicketMessage)
      .listen('.ticket.closed', setTicketHandler)
      .listen('.ticket.created', setTicketHandler);
  }
};
