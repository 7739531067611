import React from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useLocation, useRouteMatch } from 'react-router-dom';

import config from '../../../config';
import routes from '../../../routes';
import { getTopicBySlugSelector } from '../../../redux/helpCenter/selectors';
import { lastVersion } from '../../../locales';
import getMetaInformationForPage from '../../../constants/metaInformation';

const PageMeta = () => {
  const { pathname } = useLocation();
  const matchTopic = useRouteMatch({
    path: `${routes.helpCenterTopic}/`,
    strict: true,
    isExact: true,
  });
  const matchCategory = useRouteMatch({
    path: `${routes.helpCenterCategory}/`,
    strict: true,
    isExact: true,
  });
  let categorySlug = '';
  let topicSlug = '';
  const match = matchTopic || matchCategory;
  if (match) {
    const { params: { category, topic } } = match;
    categorySlug = category;
    topicSlug = topic;
  }
  const topic = useSelector(state => getTopicBySlugSelector(state, topicSlug));
  const isTicketsPage = pathname.includes(`${routes.tickets}/`);
  const isHelpCenterPage = pathname.includes(`${routes.helpCenter}/`);
  const robots = isTicketsPage ? 'noindex, nofollow' : 'index, follow';
  const { title, description } = getMetaInformationForPage(topic, categorySlug, isHelpCenterPage);

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={`${config.supportFront}${pathname}`} />
      <meta name="robots" content={robots} />
      <meta name="SupportLocales" content={`${lastVersion}`} />
    </Helmet>
  );
};

export default PageMeta;
