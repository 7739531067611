import React from 'react';

const PlHelpCenterCards = () => (
  <div className="sp-help-cards">
    <div className="sp-help-cards__body">
      <div className="sp-placeholder-cards">
        <div className="sp-simple-mesh sp-simple-mesh--v-center">
          <div className="sp-placeholder-icon sp-placeholder-icon--32" />
          <div className="sp-text">
            <div className="sp-placeholder sp-placeholder--50" />
          </div>
        </div>
        <div className="sp-simple-mesh sp-offset--t--md sp-offset--b--md">
          <div className="sp-text">
            <div className="sp-placeholder" />
            <div className="sp-placeholder sp-placeholder--50" />
          </div>
        </div>
        <div className="sp-simple-mesh sp-offset--t--md">
          <div className="sp-text">
            <div className="sp-placeholder sp-placeholder--50" />
          </div>
        </div>
      </div>
      <div className="sp-placeholder-cards">
        <div className="sp-simple-mesh sp-simple-mesh--v-center">
          <div className="sp-placeholder-icon sp-placeholder-icon--32" />
          <div className="sp-text">
            <div className="sp-placeholder sp-placeholder--50" />
          </div>
        </div>
        <div className="sp-simple-mesh sp-offset--t--md sp-offset--b--md">
          <div className="sp-text">
            <div className="sp-placeholder" />
            <div className="sp-placeholder sp-placeholder--50" />
          </div>
        </div>
        <div className="sp-simple-mesh sp-offset--t--md">
          <div className="sp-text">
            <div className="sp-placeholder sp-placeholder--50" />
          </div>
        </div>
      </div>
      <div className="sp-placeholder-cards">
        <div className="sp-simple-mesh sp-simple-mesh--v-center">
          <div className="sp-placeholder-icon sp-placeholder-icon--32" />
          <div className="sp-text">
            <div className="sp-placeholder sp-placeholder--50" />
          </div>
        </div>
        <div className="sp-simple-mesh sp-offset--t--md sp-offset--b--md">
          <div className="sp-text">
            <div className="sp-placeholder" />
            <div className="sp-placeholder sp-placeholder--50" />
          </div>
        </div>
        <div className="sp-simple-mesh sp-offset--t--md">
          <div className="sp-text">
            <div className="sp-placeholder sp-placeholder--50" />
          </div>
        </div>
      </div>
      <div className="sp-placeholder-cards">
        <div className="sp-simple-mesh sp-simple-mesh--v-center">
          <div className="sp-placeholder-icon sp-placeholder-icon--32" />
          <div className="sp-text">
            <div className="sp-placeholder sp-placeholder--50" />
          </div>
        </div>
        <div className="sp-simple-mesh sp-offset--t--md sp-offset--b--md">
          <div className="sp-text">
            <div className="sp-placeholder" />
            <div className="sp-placeholder sp-placeholder--50" />
          </div>
        </div>
        <div className="sp-simple-mesh sp-offset--t--md">
          <div className="sp-text">
            <div className="sp-placeholder sp-placeholder--50" />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default PlHelpCenterCards;
