import { handleActions } from 'redux-actions';
import { include } from '../helpers';

import * as AC from './actions';
import { sortTicketOptions } from './constants';

const initialState = {
  ticketsById: {},
  ids: [],
  attachments: [],
  pagination: {
    perPage: 10,
    total: 0,
  },
  hash: '',
  isLoaded: false,
  sort: sortTicketOptions()[0],
  unread: 0,
  activeTab: 'tickets',
};

export default handleActions(
  {
    // tickets
    [AC.SET_TICKETS]: (store, { payload: { tickets, ...rest } }) => ({
      ...store,
      ...rest,
      ticketsById: {
        ...store.ticketsById,
        ...tickets.reduce((obj, el) => (
          { ...obj, [el.id]: { ...store.ticketsById[el.id], ...el } }
        ), {}),
      },
      ids: [
        ...store.ids,
        ...tickets.map(el => el.id),
      ],
      isLoaded: true,
    }),
    [AC.SET_TICKET]: (store, { payload }) => ({
      ...store,
      ticketsById: {
        ...store.ticketsById,
        [payload.id]: { ...store.ticketsById[payload.id], ...payload },
      },
    }),
    [AC.SET_UNREAD_TICKETS]: (store, { payload }) => ({
      ...store,
      ...payload,
    }),
    [AC.UPDATE_UNREAD_TICKETS]: (store, { payload }) => ({
      ...store,
      unread: store.unread + payload,
    }),
    [AC.RESET_TICKETS]: (store) => ({
      ...store,
      isLoaded: false,
      ids: [],
      pagination: {
        perPage: 10,
        total: 0,
      },
    }),
    [AC.CHANGE_TICKETS_SORT]: (store, { payload }) => ({
      ...store,
      sort: payload,
    }),
    // attachments
    [AC.ATTACH_FILE]: (store, { payload }) => ({
      ...store,
      attachments: include(store.attachments, payload),
    }),
    [AC.UPDATE_ATTACHED_FILE]: (store, { payload }) => ({
      ...store,
      attachments: store.attachments.map(el => (el.name === payload.name ? payload : el)),
    }),
    [AC.DETACH_FILE]: (store, { payload }) => ({
      ...store,
      attachments: store.attachments.filter(el => el.id !== payload),
    }),
    [AC.DETACH_FILE_BY_NAME]: (store, { payload }) => ({
      ...store,
      attachments: store.attachments.filter(el => el.name !== payload),
    }),
    [AC.CLEAR_ATTACHED_FILES]: (store) => ({
      ...store,
      attachments: [],
    }),
    // active tab
    [AC.CHANGE_ACTIVE_TAB]: (store, { payload }) => ({
      ...store,
      activeTab: payload,
    }),
  },
  initialState,
);
