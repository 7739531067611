import React from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';

import Icon from '../../../../components/Icon';
import Text from '../../../../components/Text';
import { $l } from '../../../../locales';

const pointerStyles = { cursor: 'pointer' };

function CardItem({
  category: {
    name, icon, image, description, link,
  },
}) {
  const history = useHistory();

  const handleClick = () => {
    history.push(link);
  };

  return (
    <div
      className="sp-help-cards__item"
      style={pointerStyles}
      onClick={handleClick}
    >
      <div className="sp-help-card">
        <div className="sp-help-card__body">
          <div className="sp-help-card__section">
            <div className="sp-help-card__icon">
              {icon && icon.url ? (
                <img src={icon.url} alt="Category icon" width="32px" height="32px" />
              ) : (
                <Icon id={image} size="32" />
              )}
            </div>
            <div className="sp-help-card__title">
              <Text element="h4">{name}</Text>
            </div>
          </div>
          {description && (
            <div className="sp-help-card__desc">
              <Text>{description}</Text>
            </div>
          )}
          {link && (
            <div className="sp-help-card__section">
              <Link to={link} className="sp-help-card__link">
                {$l('READ_MORE')}
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

CardItem.propTypes = {
  category: PropTypes.shape({
    name: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    description: PropTypes.string,
    link: PropTypes.string,
    icon: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
};

export default CardItem;
