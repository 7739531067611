import { handleActions } from 'redux-actions';

import * as AC from './actions';

const initialState = {};

export default handleActions(
  {
    [AC.SET_EXPERIMENT]: (state, { payload }) => ({ ...state, ...payload }),
  },
  initialState,
);
