import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import Breadcrumbs from '../components/Breadcrumbs';
import ChatStatus from '../components/ChatHistory/ChatStatus';
import ChatMessage from '../components/ChatHistory/ChatMessage';
import TicketStatusPlaceholder from '../components/Placeholders/PlTicket';
import MainSection from '../../../components/MainSection';

import { isTaskLoading } from '../../../redux/loading/selectors';
import { CHAT_HISTORY_LOADING } from '../../../redux/loading/constants';
import { loadChatHistory } from '../../../redux/chatsHistory/thunks';
import { chatHistoryByIdSelector } from '../../../redux/chatsHistory/selectors';
import { $l } from '../../../locales';
import routes from '../../../routes';
import { isUserLoadedSelector, userIdSelector } from '../../../redux/user/selectors';
import config from '../../../config';

const ChatHistory = ({ match: { params: { id } } }) => {
  const chatId = +id;
  const dispatch = useDispatch();
  const chatHistory = useSelector(state => chatHistoryByIdSelector(state, chatId));
  const loading = useSelector(state => isTaskLoading(state, CHAT_HISTORY_LOADING));
  const isUserLoaded = useSelector(isUserLoadedSelector);
  const userId = useSelector(userIdSelector);

  const name = `${$l('CHAT')}: ${chatId}`;
  const breadcrumbs = [{
    name: $l('MY_TICKETS'),
    link: routes.tickets,
  }, {
    name,
  }];

  useEffect(() => {
    if (isUserLoaded && !userId) {
      window.location.href = `${config.USLegalFront}/login?redirect=${config.supportFront}/tickets/chat/${id}/`;
    }
  }, [isUserLoaded, userId]);

  useEffect(() => {
    dispatch(loadChatHistory(chatId));
  }, [chatId]);

  if ((!chatHistory || !chatHistory.messages) && loading) return <TicketStatusPlaceholder />;

  return (
    <Fragment>
      <MainSection className="sp-offset--b--cm">
        <Breadcrumbs items={breadcrumbs} />
      </MainSection>
      {chatHistory ? (
        <Fragment>
          <MainSection className="sp-offset--b--cm sp-offset--t--cm">
            {chatHistory && <ChatStatus name={name} status={chatHistory.rate} />}
          </MainSection>
          <MainSection className="sp-chat-history sp-offset--b--sm sp-offset--t--sm">
            <div className="sp-chat__main">
              <div className="sp-chat-main">
                {chatHistory && chatHistory.messages && chatHistory.messages.map(el => (
                  <ChatMessage key={el.id} message={el} />
                ))}
              </div>
            </div>
          </MainSection>
        </Fragment>
      ) : (
        <MainSection>
          {$l('CHAT_HISTORY_DOESNT_EXIST')}
        </MainSection>
      )}
    </Fragment>
  );
};

ChatHistory.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default ChatHistory;
