import api from '../../api';
import { loadingThunk } from '../loading/thunks';
import { CATEGORIES_LOADER } from '../loading/constants';
import { sortCategories } from './helpers';
import { setCategoriesList } from './actions';

export const loadCategoriesListAction = () => dispatch => api.get('request-category/list')
  .then(sortCategories)
  .then(setCategoriesList)
  .then(dispatch)
  .catch(() => {});

export const loadCategoriesList = loadingThunk(CATEGORIES_LOADER)(loadCategoriesListAction);
