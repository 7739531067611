import React from 'react';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';

import MainApp from './MainApp';

import history from '../../history';
import store from './store';

const DesktopApp = () => (
  <Provider store={store}>
    <Router history={history}>
      <MainApp />
    </Router>
  </Provider>
);

export default DesktopApp;
