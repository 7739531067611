import React from 'react';
import PropTypes from 'prop-types';

const ChatDelimiter = ({ text }) => (
  <div className="sp-chat-main__section">
    <div className="sp-system-message sp-system-message--with-line">
      <div className="sp-system-message__text">
        {text}
      </div>
    </div>
  </div>
);

ChatDelimiter.propTypes = {
  text: PropTypes.string.isRequired,
};

export default ChatDelimiter;
