import React from 'react';
import PropTypes from 'prop-types';

import MainSection from '../../../../components/MainSection';
import Button from '../../../../components/Button';
import { $l } from '../../../../locales';

const OpenAgain = ({ openAgain }) => (
  <MainSection>
    <div className="sp-note">
      <div className="sp-note__body">
        <div className="sp-simple-mesh sp-simple-mesh--no-wrap">
          <p className="sp-subtext">
            {$l('OPEN_AGAIN_DESC_START')}
            <b>{$l('OPEN_AGAIN')}</b>
            {$l('OPEN_AGAIN_DESC_END')}
          </p>
          <div className="control-wrapper">
            <Button
              mode="primary"
              size="sm"
              className="sp-control--w--124 sp-control--offset--l"
              onClick={openAgain}
            >
              {$l('OPEN_AGAIN')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  </MainSection>
);

OpenAgain.propTypes = {
  openAgain: PropTypes.func.isRequired,
};

export default OpenAgain;
