import React from 'react';
import PropTypes from 'prop-types';
import { matchPath } from 'react-router';
import { Link, withRouter } from 'react-router-dom';
import classNames from 'classnames';

import Icon from '../../../../components/Icon';

const NavLink = ({
  to, icon, location, exact, notice, children,
}) => (
  <Link
    to={to}
    className={classNames('sp-nav__link', {
      'is-active': !!matchPath(location.pathname, { path: to, exact }),
      'sp-nav__link--status': notice,
    })}
  >
    <div className="sp-nav__icon">
      <Icon id={icon} size="24" />
    </div>
    <div className="sp-nav__title">{children}</div>
  </Link>
);

NavLink.propTypes = {
  to: PropTypes.string.isRequired,
  icon: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  exact: PropTypes.bool,
  notice: PropTypes.bool,
};

NavLink.defaultProps = {
  icon: null,
  exact: false,
  notice: false,
};

export default withRouter(NavLink);
