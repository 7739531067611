import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form/dist/react-hook-form.ie11';
import classNames from 'classnames';

import ModalContainer from './ModalContainer';
import Icon from '../../../../components/Icon';
import Text from '../../../../components/Text';
import Button from '../../../../components/Button';
import Select from '../../../../components/Select';

import { userEmailSelector } from '../../../../redux/user/selectors';
import { closeCreateTicketModal } from '../../../../redux/modals/thunks';
import { createTicket } from '../../../../redux/tickets/thunks';
import { selectCategory } from '../../../../redux/categoriesList/actions';
import { isTaskLoading } from '../../../../redux/loading/selectors';
import { categoriesSelector, selectedCategorySelector } from '../../../../redux/categoriesList/selectors';
import { CREATE_TICKET_LOADING } from '../../../../redux/loading/constants';
import { emailRegexp } from '../../../../constants/regexp';
import { $l } from '../../../../locales';

const CreateTicket = () => {
  const dispatch = useDispatch();
  const [isErrorsExist, setErrorsExist] = useState(false);
  const userEmail = useSelector(userEmailSelector);
  const loading = useSelector(state => isTaskLoading(state, CREATE_TICKET_LOADING));
  const selectedCategory = useSelector(selectedCategorySelector);
  const categories = useSelector(categoriesSelector);

  const { handleSubmit, register, errors } = useForm({ mode: 'onBlur' });

  useEffect(() => {
    const isErrors = Object.keys(errors).length > 0;
    setErrorsExist(isErrors);
  }, [Object.keys(errors).length]);

  const closeModal = useCallback(() => {
    dispatch(closeCreateTicketModal());
  }, []);

  const onSubmit = ({ email, message }) => {
    dispatch(createTicket({
      email,
      message,
      category: selectedCategory && selectedCategory.id,
      reload: !!userEmail,
    }));
  };

  const handleChange = useCallback((value) => {
    dispatch(selectCategory(value));
  }, []);

  return (
    <ModalContainer closeModal={closeModal}>
      <button className="sp-modal__close" onClick={closeModal}>
        <Icon id="sp--16--close-small" />
      </button>
      <div className="sp-modal__header">
        <Text element="h3">{$l('CONTACT_US')}</Text>
      </div>
      <div className="sp-modal__body">
        <div className="sp-modal__body-inner">
          <div className="sp-modal__section">
            <form onSubmit={handleSubmit(onSubmit)} className="sp-form">
              <div className="sp-form__section">
                <div className="sp-form__item">
                  <label className="sp-label" htmlFor="userEmail">{$l('YOUR_EMAIL')}</label>
                  <div className="sp-input-wrapper">
                    <input
                      id="userEmail"
                      name="email"
                      defaultValue={userEmail}
                      ref={register({
                        required: $l('ENTER_EMAIL_ERROR'),
                        pattern: {
                          value: emailRegexp,
                          message: $l('CORRECT_EMAIL_ERROR'),
                        },
                      })}
                      autoFocus={!userEmail}
                      disabled={!!userEmail}
                      placeholder={$l('EMAIL_PLACEHOLDER')}
                      className={classNames('sp-input sp-input--sm', { 'sp-is-invalid': errors.email })}
                    />
                  </div>
                  {errors.email && errors.email.message && (
                    <div className="sp-subtext sp-subtext--error">{errors.email.message}</div>
                  )}
                </div>
              </div>
              <div className="sp-form__section">
                <div className="sp-form__item">
                  <label className="sp-label" htmlFor="userEmail">{$l('SELECT_A_QUESTION_CATEGORY')}</label>
                  <Select
                    value={selectedCategory}
                    options={categories}
                    onClick={handleChange}
                  />
                </div>
              </div>
              <div className="sp-form__section">
                <div className="sp-form__item">
                  <label className="sp-label" htmlFor="userProblems">{$l('YOUR_PROBLEM')}</label>
                  <textarea
                    id="userProblems"
                    name="message"
                    ref={register({
                      required: true,
                      validate: value => value.trim() !== '',
                    })}
                    autoFocus={userEmail}
                    className={classNames('sp-textarea sp-textarea--h--sm', { 'sp-is-invalid': errors.message })}
                    placeholder={$l('ASK_ANYTHING')}
                  />
                </div>
              </div>
              <div className="sp-form__actions">
                <Button
                  mode="secondary"
                  size="sm"
                  className="sp-control--w--124"
                  onClick={closeModal}
                >
                  {$l('CANCEL')}
                </Button>
                <Button
                  size="sm"
                  type="submit"
                  disabled={isErrorsExist || loading}
                  className="sp-control--w--124 sp-control--offset--l"
                >
                  {$l('SUBMIT_TICKET')}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export default CreateTicket;
