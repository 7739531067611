const config = {
  local: {
    supportUrl: '/support/uslegal/',
    socketServer: 'https://api-bumblebee.usrsprt.xyz',
    pusherKey: 'supervisor',
    USLegalApi: '/api/',
    USLegalFront: 'https://www.alpha.uslegalforms-labs.xyz',
    supportFront: 'http://localhost:4000',
  },
  dev: {
    supportUrl: '/api/',
    socketServer: 'https://api-bumblebee.usrsprt.xyz',
    pusherKey: 'supervisor',
    USLegalApi: 'https://www.alpha.uslegalforms-labs.xyz/api/',
    USLegalFront: 'https://www.alpha.uslegalforms-labs.xyz',
    supportFront: 'https://support.alpha.uslegalforms-labs.xyz',
  },
  rc: {
    supportUrl: '/api/',
    socketServer: 'https://api.usrsprt-rc.xyz',
    pusherKey: 'supervisor',
    USLegalApi: 'https://www.uslegalforms-dev.xyz/api/',
    USLegalFront: 'https://www.uslegalforms-dev.xyz',
    supportFront: 'https://support.uslegalforms-dev.xyz',
  },
  prod: {
    supportUrl: '/api/',
    socketServer: 'https://api.usrsprt.com',
    pusherKey: 'romario',
    USLegalApi: 'https://www.uslegalforms.com/api/',
    USLegalFront: 'https://www.uslegalforms.com',
    supportFront: 'https://support.uslegalforms.com',
    SENTRY_DSN: 'https://79dca5c8f66348d9bd4b3718dc13a728@o251438.ingest.sentry.io/5629198',
  },
};

// default prod config
let env = 'prod';

// for local development
if (process.env.LOCAL_ENV) {
  env = process.env.LOCAL_ENV;
}

// for builds
if (window._env_) {
  env = window._env_.ENV;

  delete window._env_;
}

export default config[env];
