import axios from 'axios';

import config from '../config';
import toCamelCaseRecursive from '../utils/camel-case';

const headers = { 'Content-Type': 'application/json' };

const instance = axios.create({
  baseURL: config.supportUrl,
  headers,
  withCredentials: true,
});

const uslInstance = axios.create({
  baseURL: config.USLegalApi,
  withCredentials: true,
});

instance.interceptors.response.use((response) => (
  Promise.resolve(toCamelCaseRecursive(response.data))
), (error) => (
  Promise.reject(error)
));

uslInstance.interceptors.response.use((response) => (
  Promise.resolve(toCamelCaseRecursive(response.data))
), (error) => (
  Promise.reject(error)
));

export { uslInstance };

export default instance;
