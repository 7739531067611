import fileSizeFormat from '../../utils/fileSizeFormat';
import toLocaleTime from '../../utils/toLocaleTime';
import { include } from '../helpers';

import { $l } from '../../locales';

export const ticketStatuses = {
  solved: () => ({
    class: 'solved',
    title: $l('RESOLVED'),
    icon: 'sp--colored--16--check',
    whiteIcon: 'sp--white--16--check',
  }),
  awaitingCustomer: () => ({
    class: 'pending',
    title: $l('WAITING_FOR_YOU'),
    icon: 'sp--colored--16--clock',
    whiteIcon: 'sp--white--16--check',
  }),
  awaitingSupport: () => ({
    class: 'pending',
    title: $l('WAITING_FOR_SUPPORT'),
    icon: 'sp--colored--16--clock',
    whiteIcon: 'sp--white--16--clock',
  }),
};

export const attachmentToView = attachment => ({
  ...attachment,
  extension: attachment.extension && attachment.extension.toUpperCase(),
  fileSize: fileSizeFormat(attachment.size),
});

export const getTicketStatus = ticket => {
  const lastMessageAuthorType = ticket.messages && ticket.messages.length > 0
    ? ticket.messages[ticket.messages.length - 1].author.type
    : ticket.lastMessage && ticket.lastMessage.author.type;

  if (ticket.closedAt) return ticketStatuses.solved();

  if (lastMessageAuthorType && lastMessageAuthorType.toLowerCase() === 'staff') {
    return ticketStatuses.awaitingCustomer();
  }

  return ticketStatuses.awaitingSupport();
};

export const getTicketName = ticket => {
  const message = (ticket.initialMessage && ticket.initialMessage.message) || ticket.subject;
  return `${ticket.id}: ${message ? message.replace(/(<.*?>)/g, ' ') : $l('NO_SUBJECT').toLowerCase()}`;
};

export const changeMessage = message => message.message.replace(/{@(.*?)}/g, (item) => {
  const attach = message.attachments.find(attachment => item.includes(attachment.name)
    && item.includes(attachment.id));

  return `<a href="${attach.url}" download="${attach.name}" style="text-decoration-line: none">${attach.name}</a>`;
});

export const mapTicketMessages = ticket => ({
  ...ticket,
  messages: ticket.messages
    && ticket.messages.reduce((acc, el) => (
      el.id !== (ticket.initialMessage && ticket.initialMessage.id)
        ? [
          ...acc,
          {
            ...el,
            message: changeMessage(el),
            createdAtFormatted: toLocaleTime(el.createdAt),
            attachments: el.attachments && el.attachments.map(attachmentToView),
          },
        ]
        : acc
    ), []),
});

export const transformDataToUnread = ({ data: { hash, ticketMessages: messages } }) => ({
  hash,
  unread: messages && messages.length,
});

export const updateTicketMessages = ({ id, message, ticket }) => ({
  id,
  messages: !ticket.messages || ticket.messages.find(el => el.id === message.id)
    ? ticket.messages
    : include(ticket.messages, message),
  lastMessage: message,
  closedAt: '',
});
