export const getCookie = (name) => {
  const regex = new RegExp(`(?:^|; )${name.replace(/([.$?*|{}()[]\\\/\+^])/g, '\\$1')}=([^;]*)`);
  const matches = document.cookie.match(regex);

  return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const setCookie = (name, value, options) => {
  const domain = `.${window.location.host.split('.').slice(-2).join('.')}`;

  const cookieOptions = {
    path: '/',
    domain,
    ...options,
  };

  if (cookieOptions.expires instanceof Date) {
    cookieOptions.expires = options.expires.toUTCString();
  }

  document.cookie = Object.entries(cookieOptions).reduce(
    (acc, [key, optValue]) => `${acc};${key}${optValue !== true ? `=${optValue}` : ''}`,
    `${encodeURIComponent(name)}=${encodeURIComponent(value)}`,
  );
};

export const deleteCookie = (name) => {
  const expires = 'Thu, 01 Jan 1970 00:00:01 GMT';

  if (Array.isArray(name)) {
    name.forEach((el) => {
      setCookie(el, '', { expires });
    });
  } else {
    setCookie(name, '', { expires });
  }
};
