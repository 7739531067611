import React, { Fragment } from 'react';

import PlTicketMessage from './PlTicketMessage';

const PlTicket = () => (
  <Fragment>
    <div className="sp-main__content sp-main__content--md sp-offset--b--md">
      <div className="sp-main__section sp-offset--b--cm">
        <div className="sp-breadcrumbs">
          <div className="sp-breadcrumbs__list">
            <div className="sp-breadcrumbs__item">
              <div className="sp-placeholder sp-placeholder--cm" />
              <div className="sp-placeholder-icon sp-placeholder-icon--14" />
            </div>
            <div className="sp-breadcrumbs__item">
              <div className="sp-placeholder sp-placeholder--cm" />
              <div className="sp-placeholder-icon sp-placeholder-icon--14" />
            </div>
            <div className="sp-breadcrumbs__item">
              <div className="sp-placeholder sp-placeholder--cm" />
            </div>
          </div>
        </div>
      </div>
      <div className="sp-main__section sp-offset--b--cm">
        <div className="sp-ticket-status">
          <div className="sp-ticket-status__body">
            <div className="sp-ticket-status__title">
              <div className="sp-text--h3">
                <div className="sp-placeholder" />
              </div>
            </div>
            <div className="sp-ticket-status__indicator">
              <div className="sp-placeholder sp-placeholder--h-24 sp-placeholder--cm" />
            </div>
          </div>
        </div>
      </div>
      <div className="sp-main__section sp-offset--t--cm">
        <div className="sp-help-center">
          <PlTicketMessage />
        </div>
      </div>
      <div className="sp-main__section">
        <div className="sp-separator">
          <div className="sp-separator__item" />
        </div>
      </div>
      <div className="sp-main__section sp-offset--t--cm">
        <div className="sp-panel">
          <div className="sp-panel__body">
            <div className="sp-panel__section sp-panel__section--between sp-panel__section--v--center">
              <div className="sp-text sp-text--h4">
                <div className="sp-placeholder" />
              </div>
              <div className="sp-panel__action">
                <div className="sp-placeholder sp-placeholder--sm" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="sp-main__section sp-offset--b--cm">
        <div className="sp-placeholder-note">
          <div className="sp-placeholder-control">
            <div className="sp-text">
              <div className="sp-placeholder" />
            </div>
          </div>
        </div>
      </div>
      <div className="sp-main__section sp-offset--t--cm">
        <div className="sp-help-center">
          <PlTicketMessage />
          <PlTicketMessage />
        </div>
      </div>
    </div>
  </Fragment>
);

export default PlTicket;