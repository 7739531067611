import React, { Fragment, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Icon from '../../../../components/Icon';
import { searchValueSelector } from '../../../../redux/search/selectors';
import { setSearchValue } from '../../../../redux/search/actions';
import { isValidSearchString } from '../../../../redux/search/helpers';
import routes from '../../../../routes';
import history from '../../../../history';
import { $l } from '../../../../locales';

let inputTimeout;

const SearchInput = () => {
  const dispatch = useDispatch();
  const ref = useRef(null);
  const searchValue = useSelector(searchValueSelector);

  useEffect(() => () => {
    clearTimeout(inputTimeout);
  }, []);

  const search = value => {
    history.push(`${routes.search}/?q=${encodeURIComponent(value)}`);
  };

  const handleChange = ({ target: { value } }) => {
    clearTimeout(inputTimeout);
    dispatch(setSearchValue(value));

    if (isValidSearchString(value)) {
      inputTimeout = setTimeout(() => {
        search(value);
      }, 1000);
    }
  };

  const startSearch = () => {
    if (isValidSearchString(searchValue)) {
      search(searchValue);
      clearTimeout(inputTimeout);
    }
  };

  const handleEnter = ({ key }) => {
    if (key === 'Enter') {
      startSearch();
    }
  };

  const handleClearInput = () => {
    dispatch(setSearchValue(''));
    clearTimeout(inputTimeout);

    if (ref.current) {
      ref.current.focus();
    }
  };

  return (
    <Fragment>
      <label className="visuallyhidden" htmlFor="inputSearch">
        {$l('SEARCH')}
      </label>
      <div className="sp-input-wrapper">
        {searchValue ? (
          <button
            className="sp-input__icon sp-input__icon--left sp-input__icon--button"
            aria-hidden="true"
            aria-label="Clear"
            tabIndex="0"
            onClick={handleClearInput}
          >
            <Icon id="sp--16--close-small" />
          </button>
        ) : (
          <label className="sp-input__icon sp-input__icon--left" aria-hidden="true" htmlFor="inputSearch">
            <Icon id="sp--16--search" />
          </label>
        )}
        <input
          id="inputSearch"
          type="text"
          ref={ref}
          placeholder={$l('SEARCH_PLACEHOLDER')}
          className="sp-input sp-input--sm sp-input--icon-left"
          value={searchValue}
          onChange={handleChange}
          onKeyUp={handleEnter}
          autoFocus
        />
      </div>
    </Fragment>
  );
};

export default SearchInput;
