import React, { Fragment, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import TicketsContainer from '../components/Tickets/TicketsContainer';
import ChatsHistoryContainer from '../components/Tickets/ChatsHistoryContainer';
import Breadcrumbs from '../components/Breadcrumbs';
import MainSection from '../../../components/MainSection';
import Tabs from '../components/Tabs';
import Text from '../../../components/Text';
import EmptyPage from '../components/EmptyPage';
import PlTickets from '../components/Placeholders/PlTickets';

import { changeActiveTab } from '../../../redux/tickets/actions';
import { tabsSelector, activeTabSelector } from '../../../redux/tickets/selectors';
import { userIdSelector, isUserLoadedSelector } from '../../../redux/user/selectors';
import { $l } from '../../../locales';
import config from '../../../config';

const Tickets = () => {
  const dispatch = useDispatch();
  const activeTab = useSelector(activeTabSelector);
  const tabs = useSelector(tabsSelector);
  const userId = useSelector(userIdSelector);
  const isUserLoaded = useSelector(isUserLoadedSelector);

  const breadcrumbs = [{ name: $l('MY_TICKETS') }];

  const changeTabHandler = useCallback((type) => {
    dispatch(changeActiveTab(type));
  }, []);

  const buttonHandler = useCallback(() => {
    window.location.href = `${config.USLegalFront}/login?redirect=${config.supportFront}/tickets/`;
  }, []);

  if (!isUserLoaded) return <PlTickets />;

  return (
    <Fragment>
      <MainSection className="sp-offset--b--cm">
        <Breadcrumbs items={breadcrumbs} />
      </MainSection>
      {userId ? (
        <Fragment>
          <MainSection className="sp-offset--t--cm sp-offset--b--sm">
            <Text element="h2">{$l('MY_TICKETS')}</Text>
          </MainSection>
          <MainSection className="sp-offset--b--cm">
            <Tabs tabs={tabs} changeTabHandler={changeTabHandler} />
          </MainSection>
          {activeTab === 'tickets' ? (
            <TicketsContainer />
          ) : (
            <ChatsHistoryContainer />
          )}
        </Fragment>
      ) : (
        <MainSection>
          <EmptyPage
            icon="sp--colored--48--document"
            title={$l('NO_TICKETS_LOGIN_TITLE')}
            description={$l('NO_TICKETS_LOGIN_DESCRIPTION')}
            buttonText={$l('LOGIN')}
            buttonHandler={buttonHandler}
            containerClasses="sp-container__inner--offset-t--xl sp-container__inner--sm"
          />
        </MainSection>
      )}
    </Fragment>
  );
};

export default Tickets;
