import React from 'react';
import PropTypes from 'prop-types';

import TicketMessage from './TicketMessage';
import PlTicketMessage from '../Placeholders/PlTicketMessage';

const TicketMessages = ({ messages, placeholderLength }) => (
  <div className="sp-help-center">
    {Array.from({ length: placeholderLength }).map((_, index) => (
      <PlTicketMessage key={index} />
    ))}
    {messages && messages.map(el => (
      <TicketMessage key={el.id} message={el} />
    ))}
  </div>
);

TicketMessages.propTypes = {
  messages: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object.isRequired),
    undefined,
  ]).isRequired,
  placeholderLength: PropTypes.number.isRequired,
};

export default TicketMessages;
