import { createSelector } from 'reselect';

import { idX } from '../helpers';
import { isCountResultInSearch } from './helpers';
import { types } from './constants';
import routes from '../../routes';

const getSearchValue = state => state.search.value;
const getSearchCountValue = state => state.search.countValue;
const getCounts = state => state.search.counts;
const getResult = state => state.search.result;
const getSelectedType = state => state.search.selectedType;

export const searchValueSelector = createSelector(getSearchValue, idX);
export const searchCountValueSelector = createSelector(getSearchCountValue, idX);
export const countsSelector = createSelector(getCounts, idX);
export const selectedTypeSelector = createSelector(getSelectedType, idX);

export const isShowTabsSelector = createSelector(getCounts, isCountResultInSearch);

export const searchResultSelector = createSelector(
  [getResult, getSelectedType],
  (result, selectedType) => {
    const data = result[selectedType];

    return !data ? [] : data.data.map(el => ({
      ...el,
      description: el.description.replace(/(<.*?>)/g, ' '),
      link: el.type === 'ticket'
        ? `${routes.tickets}/${el.id}`
        : `${routes.helpCenter}/${el.categorySlug}/${el.slug}/`,
    }));
  },
);

export const searchLoadingByTypeSelector = createSelector(
  [getResult, getSelectedType],
  (result, selectedType) => {
    const data = result[selectedType];

    return data ? data.loading : true;
  },
);

export const searchTabsSelector = createSelector(
  [getCounts, getSelectedType],
  (counts, selectedType) => types().reduce((acc, el) => {
    const foundCount = counts.find(item => item.type === el.slug);

    if (foundCount) {
      acc.push({
        ...el,
        ...foundCount,
        isActive: foundCount.type === selectedType,
        disabled: foundCount.total === 0,
      });
    }

    return acc;
  }, []),
);
