import axios from 'axios';

import api from '../../api';
import {
  setSearchCounts,
  setSearchType,
  setSearchResult,
  updateSearchResult,
  setCountValue,
} from './actions';
import { loadingThunk } from '../loading/thunks';
import { SEARCH_LOADING } from '../loading/constants';
import { PAGINATION_SIZE, types } from './constants';
import { getActiveTab } from './helpers';
import { emailValidation } from '../../utils/validations';
import { getUserID } from '../../utils/userGuestId';

let searchSource = null;
let countSource = null;

export const cancelCurrentRequests = () => {
  if (searchSource !== null) {
    searchSource.cancel();
  }
  if (countSource !== null) {
    countSource.cancel();
  }
};

export const searchByType = () => async (dispatch, getState) => {
  const { search: { value, result, selectedType }, user } = getState();
  const userEmail = emailValidation(user.email) ? user.email : null;
  const guestId = userEmail || user.id || getUserID();

  try {
    const { page, totalPages, loading } = result[selectedType];
    const nextPage = page + 1;

    if (nextPage > totalPages || loading) return;

    searchSource = axios.CancelToken.source();

    dispatch(updateSearchResult({ type: selectedType, loading: true }));

    const data = await api.get(`search?q=${encodeURIComponent(value)}&per_page=${PAGINATION_SIZE}&page=${nextPage}&scopes=${selectedType}&exp_guest_id=${encodeURIComponent(guestId)}`, { cancelToken: searchSource.token });

    dispatch(updateSearchResult({
      type: selectedType,
      page: nextPage,
      data,
      loading: false,
    }));
  } catch (e) {
    dispatch(updateSearchResult({ type: selectedType, loading: false }));
  } finally {
    searchSource = null;
  }
};

export const searchCountAction = ({ q, selectedType }) => async (dispatch, getState) => {
  const { user } = getState();
  const userEmail = emailValidation(user.email) ? user.email : null;
  const guestId = userEmail || user.id || getUserID();

  try {
    cancelCurrentRequests();
    countSource = axios.CancelToken.source();

    const res = await api.get(`search/count?q=${encodeURIComponent(q)}&exp_guest_id=${encodeURIComponent(guestId)}`, { cancelToken: countSource.token });
    const data = res.filter(el => types().find(type => type.slug === el.type));
    const result = data.reduce((acc, el) => {
      acc[el.type] = {
        page: 0,
        totalPages: Math.ceil(el.total / PAGINATION_SIZE),
        data: [],
        loading: false,
      };
      return acc;
    }, {});

    dispatch(setSearchCounts(data));
    dispatch(setSearchResult(result));
    dispatch(setCountValue(q));

    const type = getActiveTab(data, selectedType);

    if (type) {
      if (type !== selectedType) {
        dispatch(setSearchType(type));
      }

      dispatch(searchByType());
    }
  } catch (e) {
    // console.log(e);
  } finally {
    countSource = null;
  }
};

export const searchCount = loadingThunk(SEARCH_LOADING)(searchCountAction);

export const changeTab = type => async dispatch => {
  dispatch(setSearchType(type));
  dispatch(searchByType());
};
