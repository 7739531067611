import React from 'react';

const PlTabs = () => (
  <div className="sp-tabs">
    <div className="sp-tabs__body">
      <div className="sp-tabs__list">
        <div className="sp-tabs__item">
          <div className="sp-placeholder sp-placeholder--cm" />
        </div>
        <div className="sp-tabs__item">
          <div className="sp-placeholder sp-placeholder--cm" />
        </div>
      </div>
      <div className="sp-placeholder sp-placeholder--sm" />
    </div>
  </div>
);

export default PlTabs;
