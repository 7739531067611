import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const MainSection = ({ className, customStyles, children }) => (
  <div className={classNames('sp-main__section', { [className]: className })} style={customStyles}>
    {children}
  </div>
);

MainSection.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  className: PropTypes.string,
  customStyles: PropTypes.shape(),
};

MainSection.defaultProps = {
  className: '',
  customStyles: null,
};

export default MainSection;
