import React, { useEffect, useCallback, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import List from '../List';
import EmptyPage from '../EmptyPage';
import MainSection from '../../../../components/MainSection';

import { userIdSelector } from '../../../../redux/user/selectors';
import { isBottomSelector } from '../../../../redux/helper/selectors';
import { ticketsSelector } from '../../../../redux/tickets/selectors';
import { loadTickets } from '../../../../redux/tickets/thunks';
import { isTaskLoading } from '../../../../redux/loading/selectors';
import { TICKETS_LOADING } from '../../../../redux/loading/constants';
import { openCreateTicketModal } from '../../../../redux/modals/thunks';
import useMounted from '../../../../hooks/useMounted';
import { $l } from '../../../../locales';

const TicketsContainer = () => {
  const mounted = useMounted();
  const dispatch = useDispatch();
  const tickets = useSelector(ticketsSelector);
  const userId = useSelector(userIdSelector);
  const isBottom = useSelector(isBottomSelector);
  const loading = useSelector(state => isTaskLoading(state, TICKETS_LOADING));

  const buttonHandler = useCallback(() => {
    dispatch(openCreateTicketModal());
  }, []);

  useEffect(() => {
    if (userId) {
      dispatch(loadTickets());
    }
  }, [userId]);

  useEffect(() => {
    if (isBottom) {
      dispatch(loadTickets());
    }
  }, [isBottom]);

  return (
    <Fragment>
      <MainSection className="sp-offset--b--sm">
        <List items={tickets} loading={loading} />
      </MainSection>
      {!loading && !tickets.length && mounted && (
        <MainSection className="sp-offset--b--sm">
          <EmptyPage
            icon="sp--colored--48--document"
            title={$l('NO_TICKETS')}
            description={$l('NO_TICKETS_DESCRIPTION')}
            buttonText={$l('CREATE_NEW_TICKET')}
            buttonHandler={buttonHandler}
            containerClasses="sp-container__inner--offset-t--md sp-container__inner--sm"
          />
        </MainSection>
      )}
    </Fragment>
  );
};

export default TicketsContainer;
