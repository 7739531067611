import React from 'react';

import ModalsDispatcher from '../components/ModalsDispatcher';
import CreateTicket from '../components/Modals/CreateTicket';
import ThankYou from '../components/Modals/ThankYou';
import FullSizeImage from '../components/Modals/FullSizeImage';

import { CREATE_TICKET_MODAL, THANK_YOU_MODAL, FULL_SIZE_IMAGE_MODAL } from '../../../redux/modals/constants';

const Modals = () => (
  <ModalsDispatcher>
    <CreateTicket id={CREATE_TICKET_MODAL} />
    <ThankYou id={THANK_YOU_MODAL} />
    <FullSizeImage id={FULL_SIZE_IMAGE_MODAL} />
  </ModalsDispatcher>
);

export default Modals;
